import React, { useEffect, useState } from "react";

const DashboardMenu = () => {
    const [current, setCurrent] = useState(window.location.pathname);

    return (
        <div className="col-md-3 col-sm-12 floating-menu">
            <div className="fixed-bar fl-wrap" id="dash_menu">
                <div className="user-profile-menu-wrap fl-wrap block_box">
                    <div className="user-profile-menu">
                        <h3>Generale</h3>
                        <ul className="no-list-style">
                            <li>
                                <a className={"color2hover " + (current == "/user/profile" ? "user-profile-act" : "")} href="/user/profile">
                                    <i className="gmui gmui-chart-line"></i>Dashboard
                                </a>
                            </li>
                            <li>
                                <a
                                    className={"color2hover " + (current == "/user/notifications" ? "user-profile-act" : "")}
                                    href="/user/notifications">
                                    {" "}
                                    <i className="gmui gmui-rss"></i> Notificări
                                </a>
                            </li>
                            <li>
                                <a
                                    className={"color2hover " + (current == "/user/profile/edit" ? "user-profile-act" : "")}
                                    href="/user/profile/edit">
                                    <i className="gmui gmui-user-edit"></i> Editează profilul
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="mt-3 user-profile-menu">
                        <h3>Experiențe și puncte de interes</h3>
                        <ul className="no-list-style">
                            <li>
                                <a
                                    className={"color2hover " + (current == "/user/pointsofinterest" ? "user-profile-act" : "")}
                                    href="/user/pointsofinterest">
                                    <i className="gmui gmui-th-list"></i> Puncte de interes{" "}
                                </a>
                            </li>
                            <li>
                                <a
                                    className={"color2hover " + (current == "/user/experiences" ? "user-profile-act" : "")}
                                    href="/user/experiences">
                                    <i className="gmui gmui-th-list"></i> Experiențe{" "}
                                </a>
                            </li>
                            <li>
                                <a
                                    className={"color2hover " + (current == "/user/favorite" ? "user-profile-act" : "")}
                                    href="/user/favorite">
                                    <i className="gmui gmui-heart"></i> Favorite{" "}
                                </a>
                            </li>
                            <li>
                                <a className={"color2hover " + (current == "/user/review" ? "user-profile-act" : "")} href="/user/review">
                                    <i className="gmui gmui-star"></i> Review-uri{" "}
                                </a>
                            </li>
                            <li>
                                <a
                                    className={"color2hover " + (current == "/experience/create" ? "user-profile-act" : "")}
                                    href="/experience/create">
                                    <i className="gmui gmui-file-plus"></i> Adaugă o Experiență
                                </a>
                            </li>
                            <li>
                                <a
                                    className={"color2hover " + (current == "/pointofinterest/create" ? "user-profile-act" : "")}
                                    href="/pointofinterest/create">
                                    <i className="gmui gmui-file-plus"></i> Adaugă un punct de interes
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <a className="back-tofilters color2-bg custom-scroll-link logout_btn" href="/logout">
                            Deconectare <i className="gmui gmui-sign-out"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
        </div>
    );
};

export default DashboardMenu;
