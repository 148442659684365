import axios from "axios";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useState } from "react";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

const Submenu = ({ submenuType, experience }) => {
    const [userData, setUserData] = useState(getUserData());
    const [showSecondDiv, setShowSecondDiv] = useState(false);

    const handleMouseEnter = () => {
        setShowSecondDiv(true);
    };

    const handleMouseLeave = () => {
        setShowSecondDiv(false);
    };

    let _userData = JSON.parse(JSON.stringify(userData));
    let favExperiencesIDs = [];

    if (userData)
        Object.keys(_userData.user.favorites).forEach((favExperienceKey) => {
            if (
                typeof _userData.user.favorites[favExperienceKey] === "object" &&
                !Array.isArray(_userData.user.favorites[favExperienceKey]) &&
                _userData.user.favorites[favExperienceKey] !== null
            ) {
                favExperiencesIDs.push(_userData.user.favorites[favExperienceKey].id);
            } else favExperiencesIDs = _userData.user.favorites;
        });

    let favorite = () => {
        let stateFav = false;
        favExperiencesIDs &&
            favExperiencesIDs.forEach((id) => {
                if (id === experience.id) stateFav = true;
            });

        return stateFav;
    };

    const [toggleFavorite, setToggleFavorite] = useState(favorite());
    const [redirect, setRedirect] = useState(false);

    function redirectIfUserNotLoggedIn() {
        if (userData === null) setRedirect(true);
    }

    async function updateFavoriteExperience(experience) {
        if (!userData) alert("Pentru a putea adauga o experienta la favorite este necesar sa va inregistrati");

        let newData = favExperiencesIDs;
        const experienceId = experience.id;

        if (!newData.includes(experienceId)) {
            newData.push(experienceId);
        } else {
            newData.splice(newData.indexOf(experienceId), 1);
        }

        let _userData = JSON.parse(JSON.stringify(userData));
        let favExperiences = _userData.user.favorites;

        const i = favExperiences.findIndex((e) => e.id === experienceId);
        if (i > -1) {
            favExperiences.splice(i, 1);
        } else {
            favExperiences.push(experience);
        }
        _userData.user.favorites = favExperiences;

        axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + `/web/users/${userData.user.id}/favorite-experiences`,
            data: { experiences_ids: newData },
            headers: {
                Authorization: `Bearer ${userData.token}`,
            },
        })
            .then((response) => {
                console.log(response?.data);
                setToggleFavorite(!toggleFavorite);
                localStorage.setItem("userData", JSON.stringify(_userData));
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.status);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log(error.message);
                }
            });
    }

    return (
        <div className="w-full bg-white" id="gallery">
            <div className="container">
                <div className="flex flex-col items-start justify-center py-3 space-y-3 lg:items-center lg:justify-between lg:flex-row lg:space-y-0">
                    <div className="flex flex-col space-x-0 space-y-3 font-bold sm:space-x-7 text-slate-500 text-md sm:flex-row sm:space-y-0">
                        <a
                            href="#gallery"
                            className="flex flex-row items-center pr-4 space-x-2 border-r border-transparent sm:border-slate-400 text-[#7d93b2]">
                            <i className="gmui gmui-gallery" style={{ fontSize: "18px" }}></i>
                            <span>Top</span>
                        </a>
                        <a
                            href="#description"
                            className="flex flex-row items-center pr-8 space-x-1 border-r border-transparent sm:border-slate-400 text-[#7d93b2]">
                            <i className="gmui gmui-info" style={{ fontSize: "18px" }}></i>
                            <span>Detalii</span>
                        </a>
                        <a
                            href="#carousel"
                            className="flex flex-row items-center pr-8 space-x-2 border-r border-transparent sm:border-slate-400 text-[#7d93b2]">
                            <i className="gmui gmui-image-view" style={{ fontSize: "18px" }}></i>
                            <span>{submenuType}</span>
                        </a>
                        <a href="#reviews" className="flex flex-row items-center pr-4 space-x-2 text-[#7d93b2]">
                            <i className="gmui gmui-review" style={{ fontSize: "18px" }}></i>
                            <span>Recenzii</span>
                        </a>
                    </div>
                    <div
                        className="flex items-center space-x-2 bg-[#425998] py-1.5 px-3 rounded-lg cursor-pointer"
                        onClick={() => {
                            updateFavoriteExperience(experience);
                            redirectIfUserNotLoggedIn();
                        }}>
                        {userData?.user ? (
                            toggleFavorite ? (
                                <>
                                    <FavoriteBorderIcon sx={{ color: "red" }} fontSize="small" />
                                    <span className="font-semibold text-white">Elimina Favorit</span>
                                </>
                            ) : (
                                <>
                                    <FavoriteBorderIcon sx={{ color: "rgb(94,203,94)" }} fontSize="small" />
                                    <span className="font-semibold text-white">Adauga Favorit</span>
                                </>
                            )
                        ) : (
                            <>
                                <FavoriteBorderIcon fontSize="small" />
                                <span>Adauga Favorit</span>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Submenu;
