import React, { useRef, useState, useEffect, Fragment } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import { Rating } from "primereact/rating";
import { Button } from "primereact/button";
import { Modal } from "antd";

import { Toast } from "primereact/toast";
import axios from "axios";

import { Dialog, Transition } from "@headlessui/react";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

function CreateReview({ experienceId }) {
    const toast = useRef(null);
    const inputRef = useRef(null);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [imagesPreview, setImagesPreview] = useState([]);
    const [imagesURLs, setImagesURLs] = useState([]);

    const userData = getUserData();

    let [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (imagesPreview.length < 1) return;
        const newImageUrls = [];
        imagesPreview.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
        setImagesURLs(newImageUrls);
    }, [imagesPreview]);

    const formik = useFormik({
        initialValues: {
            body: "",
            score: null,
            approved: true,
            experience_id: experienceId,
            user_id: 1,
            images_upload: [],
        },
        validate: (data) => {
            let errors = {};

            if (!data.body) {
                errors.body = "Comment is required.";
            }

            if (data.body.length >= 3000) {
                errors.body = "You have exceeded the maximum number of 3000 characters";
            }

            if (!data.score) {
                errors.score = "Score is required.";
            }

            return errors;
        },
        onSubmit: (data) => {
            axios({
                method: "post",
                url: process.env.REACT_APP_API_URL + "/web/reviews",
                data: {
                    ...data,
                    score: data.score,
                    user_id: userData.user.id,
                },
                headers: {
                    'Authorization': `Bearer ${userData.token}` 
                }
            })
                .then((response) => {
                    formik.resetForm();
                    handleCancel();
                })
                .catch((error) => {
                    if (error.response) {
                        showError(error.response.status);
                    } else if (error.request) {
                        showError(error.request);
                    } else {
                        showError(error.message);
                    }
                });
        },
    });

    const showError = (detailValue) => {
        toast.current.show({ severity: "error", summary: "Error Message", detail: detailValue, life: 3000 });
    };

    // functii folosite pentru fereastra modal creare recenzie
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    // functii folosite pentru afisare erori la fields - formik
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    // transformarea imaginilor in base64 si incarcarea lor in fieldul de images_upload al formului
    const customBase64Uploader = async (event) => {
        const fileReader = new FileReader();
        await fileReader.readAsDataURL(event.target.files[0]);
        setImagesPreview([...event.target.files]);
        fileReader.onload = () => {
            formik.handleChange({
                target: { name: "images_upload", value: [{ title: event.target.files[0].name, image: fileReader.result }] },
            });
        };
    };

    // resetare afisare imagini - vizual in formular
    const resetFileInput = () => {
        setImagesPreview([]);
        setImagesURLs([]);
        closeModal();
    };

    // resetare afisare imagini - images_upload formik
    const resetImageField = () => {
        formik.handleChange({
            target: { name: "images_upload", value: [] },
        });

        setImagesPreview([]);
        setImagesURLs([]);
        inputRef.current.value = null;
    };

    function closeModal() {
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true);
    }

    return (
        <React.Fragment>
            <Toast ref={toast} />
            <div className="my-5 border-b">
                <h2 className="text-lg font-medium">Aici poți sa îți lași părerea printr-o recenzie.</h2>
                <Button className="mb-4" fontSize="small" onClick={openModal}>
                    Recenzie
                </Button>
                <Modal
                    title="Lasă o recenzie"
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={1000}
                    footer={[
                        <Button className="mx-1 p-button-sm p-button-outlined p-button-secondary" key="back" onClick={handleCancel}>
                            Inapoi
                        </Button>,
                    ]}>
                    <form onSubmit={formik.handleSubmit} className="p-fluid">
                        <div className="field">
                            <label htmlFor="score" className={classNames({ "p-error": isFormFieldValid("score") })}>
                                Evaluare*
                            </label>
                            <span className="p-float-label">
                                <Rating
                                    cancel={false}
                                    id="score"
                                    name="score"
                                    value={formik.values.score}
                                    onChange={formik.handleChange}
                                    autoFocus
                                    className={classNames({ "p-invalid": isFormFieldValid("score") })}
                                />
                            </span>

                            {getFormErrorMessage("score")}
                        </div>

                        <div className="mt-4 field">
                            <span className="p-float-label">
                                <InputTextarea
                                    id="body"
                                    name="body"
                                    value={formik.values.body}
                                    onChange={formik.handleChange}
                                    autoFocus
                                    className={classNames({ "p-invalid": isFormFieldValid("body") })}
                                />
                                <label htmlFor="body" className={classNames({ "p-error": isFormFieldValid("body") })}>
                                    Comentariu*
                                </label>
                            </span>
                            {getFormErrorMessage("body")}
                        </div>

                        <div>
                            <div className="mt-4 field">
                                <label htmlFor="images_upload" className={classNames({ "p-error": isFormFieldValid("images_upload") })}>
                                    Imagine*
                                </label>
                                <span className="p-float-label">
                                    <input type="file" id="images_upload" name="images_upload" onChange={customBase64Uploader} autoFocus />
                                </span>
                            </div>

                            <div className="mt-2">
                                {imagesURLs.map((imageSrc, index) => (
                                    <img className="h-80 w-full object-cover rounded-lg" key={index} src={imageSrc} />
                                ))}
                            </div>
                            {imagesPreview.length !== 0 && (
                                <button
                                    // style={{ textAlign: "center", padding: "auto", border: "1px solid black" }}
                                    className="w-full px-auto mt-3 p-button-sm p-button-danger bg-red-500 py-2 rounded-md text-white font-medium text-base"
                                    type="button"
                                    onClick={resetImageField}>
                                    Sterge {imagesPreview.length === 1 ? "imaginea" : "imaginile"}
                                </button>
                            )}
                        </div>

                        <Button type="submit" onClick={resetFileInput} label="Trimite" className="mt-2" />
                    </form>
                </Modal>

                <Transition appear show={isOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={closeModal}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto z-10">
                            <div className="flex min-h-full items-center justify-center p-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95">
                                    <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                            Lasă o recenzie
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <form onSubmit={formik.handleSubmit} className="p-fluid">
                                                <div className="field">
                                                    <label htmlFor="score" className={classNames({ "p-error": isFormFieldValid("score") })}>
                                                        Evaluare*
                                                    </label>
                                                    <span className="p-float-label">
                                                        <Rating
                                                            cancel={false}
                                                            id="score"
                                                            name="score"
                                                            value={formik.values.score}
                                                            onChange={formik.handleChange}
                                                            autoFocus
                                                            className={classNames({ "p-invalid": isFormFieldValid("score") })}
                                                        />
                                                    </span>

                                                    {getFormErrorMessage("score")}
                                                </div>

                                                <div className="mt-4 field">
                                                    <span className="p-float-label">
                                                        <InputTextarea
                                                            id="body"
                                                            name="body"
                                                            value={formik.values.body}
                                                            onChange={formik.handleChange}
                                                            autoFocus
                                                            className={classNames({ "p-invalid": isFormFieldValid("body") })}
                                                        />
                                                        <label
                                                            htmlFor="body"
                                                            className={classNames({ "p-error": isFormFieldValid("body") })}>
                                                            Comentariu*
                                                        </label>
                                                    </span>
                                                    {getFormErrorMessage("body")}
                                                </div>

                                                <div>
                                                    <div className="mt-4 field">
                                                        <label
                                                            htmlFor="images_upload"
                                                            className={classNames({ "p-error": isFormFieldValid("images_upload") })}>
                                                            Imagine*
                                                        </label>
                                                        <span className="p-float-label">
                                                            <input
                                                                ref={inputRef}
                                                                type="file"
                                                                id="images_upload"
                                                                name="images_upload"
                                                                onChange={customBase64Uploader}
                                                                autoFocus
                                                            />
                                                        </span>
                                                    </div>

                                                    <div className="mt-2">
                                                        {imagesURLs.map((imageSrc, index) => (
                                                            <img
                                                                className="h-80 w-full object-cover rounded-lg"
                                                                key={index}
                                                                src={imageSrc}
                                                            />
                                                        ))}
                                                    </div>
                                                    {imagesPreview.length !== 0 && (
                                                        <button
                                                            // style={{ textAlign: "center", padding: "auto", border: "1px solid black" }}
                                                            className="inline-flex justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 mt-2"
                                                            type="button"
                                                            onClick={resetImageField}>
                                                            Sterge {imagesPreview.length === 1 ? "imaginea" : "imaginile"}
                                                        </button>
                                                    )}
                                                </div>

                                                <button
                                                    type="submit"
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mt-2 mr-2"
                                                    onClick={resetFileInput}>
                                                    Trimite
                                                </button>
                                                <button
                                                    type="button"
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mt-2"
                                                    onClick={closeModal}>
                                                    Inchide
                                                </button>
                                            </form>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
            </div>
        </React.Fragment>
    );
}

export default CreateReview;
