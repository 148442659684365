import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap, ZoomControl, CircleMarker } from "react-leaflet";
import { Skeleton } from "primereact/skeleton";
import utils from "../../utils/utils";

import { useGetAttractionQuery } from "../../services/smartsenseApi";

function MiniMapPOI({ iconType, point_of_intrest_id }) {
    console.log(point_of_intrest_id);
    const [pointOfIntrest, setPointOfIntrest] = useState([]);
    const {
        data: singlePoint_of_intrest,
        isFetching: isFetchingSinglePoint_of_intrest,
        error: errorSinglePoint_of_intrest,
    } = useGetAttractionQuery(point_of_intrest_id);

    useEffect(() => {
        if (!isFetchingSinglePoint_of_intrest && !errorSinglePoint_of_intrest) {
            setPointOfIntrest(singlePoint_of_intrest?.data);
        }
    }, [isFetchingSinglePoint_of_intrest]);

    return (
        <div>
            {pointOfIntrest.length === 0 ? (
                <p>Nu exista punct de interes pe harta</p>
            ) : (
                <MapContainer
                    center={[pointOfIntrest?.latitude, pointOfIntrest?.longitude]}
                    zoom={13}
                    scrollWheelZoom={false}
                    style={{ height: "500px", width: "100%" }}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={[pointOfIntrest?.latitude, pointOfIntrest?.longitude]} icon={utils.changeColor(iconType)}>
                        <Popup>Locația punctului de interes - {pointOfIntrest?.title}</Popup>
                    </Marker>
                    <CircleMarker center={[pointOfIntrest?.latitude, pointOfIntrest?.longitude]} radius={40}>
                        <Popup>Zona estimativă</Popup>
                    </CircleMarker>
                </MapContainer>
            )}
        </div>
    );
}

export default MiniMapPOI;
