import axios from "axios";

export const getApiExperiences = async () => {
    try {
        const response = await axios.get(`https://dev.smartsense.ro/api/mobile/experiences`);
        const experiences = response.data;
        return { success: true, error: false, data: experiences };
    } catch (error) {
        return { success: false, error: true };
    }
};

export const getApiPointsOfInterest = async () => {
    try {
        const response = await axios.get(`https://dev.smartsense.ro/api/web/points-of-interest`);
        const pointsOFInterest = response.data;
        return { success: true, error: false, data: pointsOFInterest };
    } catch (error) {
        return { success: false, error: true };
    }
};

export const getApiExperience = async (experienceId) => {
    try {
        const response = await axios.get(`https://dev.smartsense.ro/api/web/experiences/${experienceId}`);
        const experience = response.data;
        return { success: true, error: false, data: experience };
    } catch (error) {
        return { success: false, error: true };
    }
};

export const getApiPointOfInterest = async (pointOfInterestId) => {
    try {
        const response = await axios.get(`https://dev.smartsense.ro/api/web/points-of-interest/${pointOfInterestId}`);
        const pointOfInterest = response.data;
        return { success: true, error: false, data: pointOfInterest };
    } catch (error) {
        return { success: false, error: true };
    }
};

export const getApiPointsOfInterestsOfExperience = async (experienceId, token) => {
    try {
        const response = await axios.get(`https://dev.smartsense.ro/api/web/experiences/${experienceId}/points-of-interest`);
        const pointsOfInterest = response.data;
        return { success: true, error: false, data: pointsOfInterest };
    } catch (error) {
        return { success: false, error: true };
    }
};

export const getApiReviewsOfExperience = async (experienceId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    try {
        const response = await axios.get(`https://dev.smartsense.ro/api/web/experiences/${experienceId}/reviews`, config);
        const reviews = response.data;
        return { success: true, error: false, data: reviews };
    } catch (error) {
        return { success: false, error: true };
    }
};

export const getApiExperiencesOfPointOfInterest = async (pointOfInterestId) => {
    try {
        const response = await axios.get(`https://dev.smartsense.ro/api/web/points-of-interest/${pointOfInterestId}/experiences`);
        const experiences = response.data;
        return { success: true, error: false, data: experiences };
    } catch (error) {
        return { success: false, error: true };
    }
};

export const getApiTags = async () => {
    try {
        const response = await axios.get(`https://dev.smartsense.ro/api/web/tags`);
        const tags = response.data.data;
        return { success: true, error: false, data: tags };
    } catch (error) {
        return { success: false, error: true };
    }
};

export const getApiExperienceCategories = async () => {
    try {
        const response = await axios.get(`https://dev.smartsense.ro/api/web/experience-categories`);
        const tags = response.data.data;
        return { success: true, error: false, data: tags };
    } catch (error) {
        return { success: false, error: true };
    }
};

export const getApiPOICategories = async () => {
    try {
        const response = await axios.get(`https://dev.smartsense.ro/api/web/point-of-interest-categories`);
        const tags = response.data.data;
        return { success: true, error: false, data: tags };
    } catch (error) {
        return { success: false, error: true };
    }
};
export const getApiExperiencesOfUser = async (userId, token) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        const response = await axios.get(`https://dev.smartsense.ro/api/web/users/${userId}/experiences`, config);
        const experiences = response.data.data;
        return { success: true, error: false, data: experiences };
    } catch (error) {
        return { success: false, error: true };
    }
};

export const getApiPointOfInterestOfUser = async (userId, token) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        const response = await axios.get(`https://dev.smartsense.ro/api/web/users/${userId}/points-of-interest`, config);
        const pointsOfInterest = response.data.data;
        return { success: true, error: false, data: pointsOfInterest };
    } catch (error) {
        return { success: false, error: true };
    }
};

export const getApiFavoriteExperiencesOfUser = async (userId, token) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        const response = await axios.get(`https://dev.smartsense.ro/api/web/users/${userId}/favorite-experiences`, config);
        const experiences = response.data.data;
        return { success: true, error: false, data: experiences };
    } catch (error) {
        return { success: false, error: true };
    }
};

export const getApiReviewsOfExperiencesOfUser = async (userId, token) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        const response = await axios.get(`https://dev.smartsense.ro/api/web/users/${userId}/experiences-reviews`, config);
        const reviews = response.data.data;
        return { success: true, error: false, data: reviews };
    } catch (error) {
        return { success: false, error: true };
    }
};

export const getApiNotificationsOfUser = async (userId) => {
    try {
        const response = await axios.get(`https://dev.smartsense.ro/api/mobile/notifications?user_id=${userId}`);
        const notifications = response.data;
        return { success: true, error: false, data: notifications };
    } catch (error) {
        return { success: false, error: true };
    }
};
