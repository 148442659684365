import React, { useState, useEffect } from "react";
import { Link as LinkT, useLocation } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import MiniMap from "../map/MiniMapExperience";
import ShareIcon from "@mui/icons-material/Share";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import WebIcon from "@mui/icons-material/Web";
import { Skeleton } from "primereact/skeleton";
import { Button } from "primereact/button";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import CreateReview from "./CreateReview";
import { Link, Element } from "react-scroll";
import { Markup } from "interweave";
import PreviewImage1 from "../previewimages/PreviewImage1";
import PreviewImage3 from "../previewimages/PreviewImage3";
import PreviewImage4 from "../previewimages/PreviewImage4";
import PreviewImage5 from "../previewimages/PreviewImage5";
import axios from "axios";
import CarouselPOI from "./CarouselPOI";
import Reviews from "./review/Reviews";
import ReviewsHeader from "./review/ReviewsHeader";
import { getApiExperience } from "../../services/apiServices";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

function Experience() {
    const location = useLocation();
    const navigate = useNavigate();
    const { experienceId } = useParams();
    const [userData, setUserData] = useState(getUserData());
    const [experience, setExperience] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [redirect, setRedirect] = useState(false);
    const [showLogin, setShowLogin] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const response = await getApiExperience(experienceId);

            setIsFetching(response.success === true && response.error === false ? false : true);

            if (!isFetching) {
                if (response.success === false && response.error === true) navigate("/error/404");
            }

            setExperience(response.data.data);
        };

        fetchData();
    }, []);

    const hide_login = () => {
        setShowLogin(false);
    };

    const do_favorite = (experience_id) => {
        let favExperiences = [];
        let objects = true;
        let _userData = JSON.parse(JSON.stringify(userData));

        if (!userData) {
            // setShowLogin(true);
            alert("Pentru a putea adauga o experienta la favorite este necesar sa va inregistrati");
            return;
        }
        Object.keys(_userData.user.favorites).forEach((favExperienceKey) => {
            if (
                typeof _userData.user.favorites[favExperienceKey] === "object" &&
                !Array.isArray(_userData.user.favorites[favExperienceKey]) &&
                _userData.user.favorites[favExperienceKey] !== null
            ) {
                favExperiences.push(_userData.user.favorites[favExperienceKey].id);
            } else favExperiences = _userData.user.favorites;

            objects = false;
        });

        if (favExperiences.includes(experience_id)) {
            var index = favExperiences.indexOf(experience_id);
            if (index !== -1) {
                favExperiences.splice(index, 1);
            }
        } else {
            favExperiences.push(experience_id);
        }

        _userData.user.favorites = favExperiences;
        localStorage.setItem("userData", JSON.stringify(_userData));

        axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + "/web/users/" + _userData.user.id + "/favorite-experiences",
            data: {
                experiences_ids: favExperiences,
            },
        }).then(
            (response) => {
                console.log(response);
            },
            (error) => {
                alert("Eroare la experiente favorite");
                console.log(error);
            }
        );
        setUserData(_userData);
    };

    // afisarea imagini / imaginilor pentru experienta
    function ChooseImagePreview(props) {
        if (props.value.length === 0)
            return (
                <img
                    className="object-cover w-[100%] h-[60vh] min-h-[20rem] max-h-[42rem] rounded-lg"
                    src="/default/default-image.png"
                    alt=""
                />
            );
        else if (props.value.length === 1) return <PreviewImage1 images={props.value} />;
        else if (props.value.length === 3) return <PreviewImage3 images={props.value} />;
        else if (props.value.length === 4) return <PreviewImage4 images={props.value} />;
        else if (props.value.length > 4) return <PreviewImage5 images={props.value} />;
    }

    return (
        <>
            <Header />
            <div className="container mx-auto">
                {experience?.title !== undefined ? (
                    <h1 className="my-3 text-3xl font-base">{experience?.title}</h1>
                ) : (
                    <Skeleton className="my-3" width="30rem" height="2rem"></Skeleton>
                )}
                <div className="flex flex-col justify-between md:flex-row">
                    <div className="flex flex-row space-x-3">
                        {experience?.score !== undefined ? (
                            <h1>★ {experience?.score}</h1>
                        ) : (
                            <Skeleton className="" width="3rem" height="1.25rem"></Skeleton>
                        )}

                        <ReviewsHeader experienceId={experienceId} />
                    </div>
                    <div className="flex flex-row space-x-1">
                        <button className="flex items-center hover:bg-[rgb(240,240,240)] py-1 px-2 rounded-lg">
                            <ShareIcon fontSize="small" />
                            <span>Trimite</span>
                        </button>
                        <button
                            onClick={() => do_favorite(experience?.id)}
                            className="flex items-center hover:bg-[rgb(240,240,240)] py-1 px-2 rounded-lg">
                            {userData?.user ? (
                                userData.user.favorites.includes(experience?.id) ? (
                                    <>
                                        <FavoriteBorderIcon sx={{ color: "red" }} fontSize="small" />
                                        <span style={{ color: "red" }}>Elimina Favorit</span>
                                    </>
                                ) : (
                                    <>
                                        <FavoriteBorderIcon sx={{ color: "rgb(94,203,94)" }} fontSize="small" />
                                        <span style={{ color: "rgb(94,203,94)" }}>Adauga Favorit</span>
                                    </>
                                )
                            ) : (
                                <>
                                    <FavoriteBorderIcon fontSize="small" />
                                    <span>Adauga Favorit</span>
                                </>
                            )}
                        </button>
                    </div>
                </div>

                {/* imagine / images */}
                {experience?.images !== undefined ? (
                    <ChooseImagePreview value={experience?.images} />
                ) : (
                    <Skeleton width="w-full" height="35rem"></Skeleton>
                )}

                <div className="my-4 md:w-[65%] w-full">
                    {/* Experiente si taguri */}
                    <div className="flex flex-col py-3 border-b">
                        <div className="flex flex-row mb-2 space-x-2 text-lg font-medium">
                            {experience?.tags !== undefined ? (
                                experience.tags.map((tag, index) => {
                                    return <span key={index}>#{tag.tag.toLowerCase()}</span>;
                                })
                            ) : (
                                <Skeleton className="" width="40rem" height="1.5rem"></Skeleton>
                            )}
                        </div>
                    </div>

                    {/* Experiente */}
                    <div className="py-3 space-y-4 border-b">
                        {experience?.start_date !== undefined && experience?.end_date !== undefined ? (
                            <div className="flex space-x-4">
                                <CalendarMonthIcon fontSize="large" />
                                <div className="flex flex-col">
                                    <h3 className="font-semibold">Perioada activitatii</h3>
                                    <p className="mt-1 text-sm font-normal text-neutral-500">
                                        Incepand cu data de{" "}
                                        <span className="font-medium text-black">{experience?.start_date.split(" ", 1)}</span> pana in data
                                        de <span className="font-medium text-black">{experience?.end_date.split(" ", 1)}</span>
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <Skeleton width="w-full" height="4rem"></Skeleton>
                        )}

                        {experience?.youtube_url !== undefined ? (
                            experience?.youtube_url && (
                                <div className="flex space-x-4">
                                    <YouTubeIcon fontSize="large" />
                                    <div className="flex flex-col">
                                        <h3 className="font-semibold">Youtube</h3>
                                        <LinkT
                                            className="mt-1 text-sm font-normal text-neutral-500"
                                            target="_blank"
                                            to={experience?.youtube_url}>
                                            {experience?.youtube_url}
                                        </LinkT>
                                    </div>
                                </div>
                            )
                        ) : (
                            <Skeleton width="w-full" height="4rem"></Skeleton>
                        )}
                        {experience?.facebook_url !== undefined ? (
                            experience?.facebook_url && (
                                <div className="flex space-x-4">
                                    <FacebookIcon fontSize="large" />
                                    <div className="flex flex-col">
                                        <h3 className="font-semibold">Facebook</h3>
                                        <LinkT
                                            className="mt-1 text-sm font-normal text-neutral-500"
                                            target="_blank"
                                            to={experience?.facebook_url}>
                                            {experience?.facebook_url}
                                        </LinkT>
                                    </div>
                                </div>
                            )
                        ) : (
                            <Skeleton width="w-full" height="4rem"></Skeleton>
                        )}
                        {experience?.website_url !== undefined ? (
                            experience?.website_url && (
                                <div className="flex space-x-4">
                                    <WebIcon fontSize="large" />
                                    <div className="flex flex-col">
                                        <h3 className="font-semibold">Website</h3>
                                        <LinkT
                                            className="mt-1 text-sm font-normal text-neutral-500"
                                            target="_blank"
                                            to={experience?.website_url}>
                                            {experience?.website_url}
                                        </LinkT>
                                    </div>
                                </div>
                            )
                        ) : (
                            <Skeleton width="w-full" height="4rem"></Skeleton>
                        )}
                    </div>

                    {/* Descriere */}
                    <div className="py-3 border-b">
                        <div className="whitespace-pre-line">
                            {experience.description !== undefined ? (
                                <Markup
                                    content={experience.description
                                        .split("\r\n")
                                        .map((s) => `<p class="indent-4">${s}</p>`)
                                        .join("\r\n")}
                                />
                            ) : (
                                <Skeleton width="w-full" height="14rem"></Skeleton>
                            )}
                        </div>
                    </div>

                    <CarouselPOI experienceId={experienceId} />
                </div>

                <Reviews experienceId={experienceId} />

                {userData !== null ? (
                    <CreateReview experienceId={experienceId} />
                ) : (
                    <div className="my-5 border-b">
                        <h2 className="text-lg font-medium">Contecteaza-te ca sa scrii o recenzie.</h2>
                        <Button className="mb-4" fontSize="small" onClick={() => setRedirect(true)}>
                            Autentificare
                        </Button>
                        {redirect && <Navigate to="/login" replace state={{ from: location }} />}
                    </div>
                )}

                <div className="pb-4">
                    <h1 className="m-0 text-2xl">Locație</h1>
                    {experience?.id !== undefined && <MiniMap iconType={"home"} experience_id={experience?.id} />}
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Experience;
