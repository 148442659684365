import React from "react";
import { Navigate, Outlet, useLocation, Route } from "react-router-dom";
import Login from "./Login";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

const PrivateRoute = ({ children }) => {
    // const location = useLocation();
    const isAuthenticated = getUserData();

    // return isAuthenticated ? <Outlet /> : <Navigate to="/login" replace state={{ from: location }} />;

    let paths = [];
    paths["noauth"] = ["MapPlaces", "Register", "Login", "Logout", "Sensors", "Sensor", "Error"];
    paths["person"] = [
        "MapPlaces",
        "Register",
        "Login",
        "Logout",
        "Sensors",
        "Sensor",
        "Error",
        "UserProfile",
        "UserProfileEdit",
        "FavoriteMapComp",
        "UserNotifications",
        "FavoriteUser",
        "ReviewUser",
    ];
    paths["company"] = [
        "MapPlaces",
        "Register",
        "Login",
        "Logout",
        "Sensors",
        "Sensor",
        "Error",
        "UserProfile",
        "UserProfileEdit",
        "FavoriteMapComp",
        "CreatePointofinterest",
        "EditPointofinterest",
        "CreateExperience",
        "EditExperience",
        "UserPointsofinterest",
        "UserExperiences",
        "UserNotifications",
        "FavoriteUser",
        "ReviewUser",
    ];
    paths["admin"] = [
        "MapPlaces",
        "Register",
        "Login",
        "Logout",
        "Sensors",
        "Sensor",
        "Error",
        "UserProfile",
        "UserProfileEdit",
        "FavoriteMapComp",
        "CreatePointofinterest",
        "EditPointofinterest",
        "CreateExperience",
        "EditExperience",
        "UserPointsofinterest",
        "UserExperiences",
        "UserNotifications",
        "FavoriteUser",
        "ReviewUser",
    ];

    if (isAuthenticated) {
        let code = isAuthenticated.user.roles[0].code ? isAuthenticated.user.roles[0].code : "noauth";
        if (paths[isAuthenticated.user.roles[0].code].includes(children.type.name)) return children;
        else return <Navigate to="/subscription" />;
    }

    return <Navigate to="/login" />;
};

export default PrivateRoute;
