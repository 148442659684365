const ItemHowItWorks = ({ image, title, text, number }) => {
    return (
        <div className="max-w-[400px] bg-[#f9f9f9] flex flex-col justify-start items-center rounded-lg border border-gray-500 px-4 py-5 space-y-3 relative">
            <img className="object-cover w-20 h-20" src={image} alt="icon" />
            <h3 className="text-xl font-bold text-[#566985]">{title}</h3>
            <p className="text-center text-[#7d93b2]">{text}</p>
            <div className="absolute transform -translateY(-50%) flex items-center justify-center w-12 h-12 bg-white border border-gray-500 rounded-full shadow-sm -bottom-6 ">
                <span className="text-[#4DB7FE] font-bold text-md">{number}</span>
            </div>
        </div>
    );
};

export default ItemHowItWorks;
