import { Skeleton } from "primereact/skeleton";
import { useState, useEffect } from "react";
import { getApiReviewsOfExperience } from "../../../services/apiServices";

const ReviewsHeader = ({ experienceId }) => {
    const [reviews, setReviews] = useState(null);
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const response = await getApiReviewsOfExperience(experienceId);
            setIsFetching(response.success === true && response.error === false ? false : true);
            setReviews(response.data);
        };

        fetchData();
    }, []);
    return (
        <div>
            {isFetching ? (
                <Skeleton className="" width="6rem" height="1.25rem"></Skeleton>
            ) : (
                <h3>
                    ·{" "}
                    <div className="inline font-semibold text-black underline underline-offset-1 hover:text-black">
                        {reviews?.data.length} recenzii
                    </div>
                </h3>
            )}
        </div>
    );
};

export default ReviewsHeader;
