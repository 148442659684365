import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap, ZoomControl } from "react-leaflet";
import { useMapEvents } from "react-leaflet";
import { useSelector, useDispatch } from "react-redux";
import { boundsActions } from "../../slice/map/bounds-slice";
import { placeActions } from "../../slice/map/place-slice";
import { listActions } from "../../slice/map/list-slice";
import L from "leaflet";
import { useGetAttractionsQuery, useGetFavoriteUserExperiencesQuery, useGetExperiencesQuery } from "../../services/smartsenseApi";
import Place from "./Place";

import utils from "../../utils/utils";
import "./style.css";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

const CenterMap = () => {
    const [position, setPosition] = useState(null);
    const [bbox, setBbox] = useState([]);
    const map = useSelector((state) => state.place.map);

    useEffect(() => {
        if (!map) return;

        const customControlerGetMap = L.Control.extend({
            options: {
                position: "bottomright",
            },

            onAdd: function () {
                const btn = L.DomUtil.create("button");
                btn.title = "Home Button";
                btn.innerHTML =
                    "<svg class='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc' focusable='false' aria-hidden='true' viewBox='0 0 24 24' data-testid='AdjustIcon'><path d='M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3-8c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3 3 1.34 3 3z'></path></svg>";
                btn.className = "bg-[rgba(255,255,255,0.95)] h-8 px-1 rounded-full font-semibold text-base";

                btn.onclick = () => {
                    map.locate().on("locationfound", function (e) {
                        setPosition(e.latlng);
                        setBbox(e.bounds.toBBoxString().split(","));
                    });

                    return position === null ? null : (
                        <Marker position={position} className="bg-black" icon={utils.changeColor("human")}>
                            <Popup>
                                You are here. <br />
                                Map bbox: <br />
                                <b>Southwest lng</b>: {bbox[0]} <br />
                                <b>Southwest lat</b>: {bbox[1]} <br />
                                <b>Northeast lng</b>: {bbox[2]} <br />
                                <b>Northeast lat</b>: {bbox[3]}
                            </Popup>
                        </Marker>
                    );
                };
                return btn;
            },
        });

        map.addControl(new customControlerGetMap());
    }, [map]);

    return null;
};

const ModeMap = () => {
    const map = useSelector((state) => state.place.map);
    const mapMode = useSelector((state) => state.list.toggleMapMod);
    const dispatch = useDispatch();
    const [valueMode, setValueMode] = useState(false);

    useEffect(() => {
        if (!map) return;

        const customControlerMapMode = L.Control.extend({
            options: {
                position: "bottomleft",
            },

            onAdd: function () {
                const btn = L.DomUtil.create("button");
                btn.title = "Grid / Satellite";
                // btn.style.backgroundImage = mapMode ? "url('/modeMap/satellite.png')" : "url('/modeMap/grid.png')";
                btn.className = "bg-[rgba(255,255,255,0.95)] h-12 w-20 px-1 rounded-md font-semibold text-base";
                btn.innerHTML = "Grid / Satellite";

                btn.onclick = () => {
                    dispatch(listActions.toggleMapMode());
                };
                return btn;
            },
        });

        map.addControl(new customControlerMapMode());
    }, [map, valueMode]);
};
function UpdatePlacesAndBounds() {
    const dispatch = useDispatch();
    const map = useMapEvents({
        moveend: () => {
            const bounds = map.getBounds();
            // dispatch(boundsActions.updateBounds(bounds));
            dispatch(boundsActions.updatePlaces(bounds));
            dispatch(boundsActions.updateExperiences());
        },
    });
    return null;
}

const Markers = () => {
    const placesRedux = useSelector((state) => state.bounds.places);
    const dispatch = useDispatch();

    return placesRedux.map((place, index) => {
        return (
            <Marker
                key={index}
                position={[place?.latitude, place?.longitude]}
                icon={L.divIcon({
                    html: `
                        <svg style='fill:${place?.point_of_interest_category?.color}' class='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch' focusable='false' aria-hidden='true' viewBox='0 0 24 24' data-testid='FmdGoodIcon'><path d='M12 2c-4.2 0-8 3.22-8 8.2 0 3.32 2.67 7.25 8 11.8 5.33-4.55 8-8.48 8-11.8C20 5.22 16.2 2 12 2zm0 10c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z'></path></svg>`,
                    className: "",
                    iconSize: [34, 45],
                    iconAnchor: [12, 30],
                })}
                eventHandlers={{
                    click: () => dispatch(listActions.statusPlaceList(place)),
                }}>
                <Popup>
                    <Place place={place} key={index} />
                </Popup>
            </Marker>
        );
    });
};

function LocationMarker() {
    const [position, setPosition] = useState(null);
    const [bbox, setBbox] = useState([]);
    const dispatch = useDispatch();

    const map = useMap();

    useEffect(() => {
        map.locate()
            .on("locationfound", function (e) {
                setPosition(e.latlng);
                dispatch(placeActions.getUserLocation(e.latlng));
                setBbox(e.bounds.toBBoxString().split(","));
            })
            .on("locationerror", function (e) {
                //do nothing
            });
    }, [map]);

    return position === null ? null : (
        <Marker position={position} icon={utils.changeColor("human")}>
            <Popup>
                You are here. <br />
                Map bbox: <br />
                <b>Southwest lng</b>: {bbox[0]} <br />
                <b>Southwest lat</b>: {bbox[1]} <br />
                <b>Northeast lng</b>: {bbox[2]} <br />
                <b>Northeast lat</b>: {bbox[3]}
            </Popup>
        </Marker>
    );
}

function Map() {
    const dispatch = useDispatch();
    const user = getUserData();
    const { data: PointsOfInterest, isFetching: isFetchingPointsOfInterest, error: errorPointsOfInterest } = useGetAttractionsQuery();
    const { data: experiences, isFetching: isFetchingExperiences, error: errorExperiences } = useGetFavoriteUserExperiencesQuery(user.user['id']);
    // const { data: experiences, isFetching: isFetchingExperiences, error: errorExperiences } = useGetExperiencesQuery();
    const map = useSelector((state) => state.place.map);
    const mapMode = useSelector((state) => state.list.toggleMapMod);
    const refMapMode = useRef(null);

    const v1map = {
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attr: "copy; <a href=https://www.openstreetmap.org/copyright>OpenStreetMap</a> contributors",
    };

    const v2map = {
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        attr: "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
    };

    let lite_places = [];

    useEffect(() => {
        if (refMapMode.current) {
            refMapMode.current.setUrl(mapMode ? v1map.url : v2map.url);
        }
    }, [mapMode]);

    useEffect(() => {
        if (!isFetchingExperiences && !errorExperiences) {
            const experiente = experiences?.data;
            console.log(experiente);
            dispatch(boundsActions.updateAllLiteExperiences(experiente));
        }
    }, [isFetchingExperiences]);

    // useEffect(() => {
    //     if (!isFetchingPointsOfInterest && !errorPointsOfInterest && !isFetchingExperiences && !errorExperiences) {

    //         var punctele = [];
    //         Object.keys(experiences['data']).forEach((place) => {
    //             punctele = punctele.concat(experiences['data'][place].points_of_interest);
    //         });

    //         const punctele_de_interes = PointsOfInterest?.data;
    //         Object.keys(punctele_de_interes).forEach((place) => {
    //             lite_places.push({
    //                 id: punctele_de_interes[place].id,
    //                 title: punctele_de_interes[place].title,
    //                 experiences_length: punctele_de_interes[place].experiences.length,
    //                 image_url: punctele_de_interes[place]?.images[0]?.url,
    //                 point_of_interest_category: punctele_de_interes[place].point_of_interest_category,
    //                 latitude: punctele_de_interes[place].latitude,
    //                 longitude: punctele_de_interes[place].longitude,
    //             });
    //         });
    //         dispatch(boundsActions.updateAllLitePlaces(punctele_de_interes));

    //         console.log(punctele_de_interes[0]);
    //         console.log(punctele[0]);

    //         if (map) {
    //             const bounds = map.getBounds();
    //             dispatch(boundsActions.updatePlaces(bounds));
    //         }
    //     }
    // }, [isFetchingPointsOfInterest]);


    useEffect(() => {
        if (!isFetchingPointsOfInterest && !errorPointsOfInterest) {
            let punctele = [];

            Object.keys(experiences['data']).forEach((place) => {
                punctele = punctele.concat(experiences['data'][place].points_of_interest);
            });

            let _punctele = JSON.parse(JSON.stringify(punctele));

            Object.keys(punctele).forEach((place) => {
                let result = punctele[place].experiences.map(a => a.id);
                _punctele[place].experiences = result;

                lite_places.push({
                    id: punctele[place].id,
                    title: punctele[place].title,
                    experiences_length: punctele[place].experiences.length,
                    image_url: punctele[place]?.images[0]?.url,
                    point_of_interest_category: punctele[place].point_of_interest_category,
                    latitude: punctele[place].latitude,
                    longitude: punctele[place].longitude,
                });
            });

            dispatch(boundsActions.updateAllLitePlaces(_punctele));

            if (map) {
                const bounds = map.getBounds();
                dispatch(boundsActions.updatePlaces(bounds));
            }
        }
    }, [isFetchingPointsOfInterest]);


    useEffect(() => {
        if (!isFetchingPointsOfInterest && !errorPointsOfInterest && !isFetchingExperiences && !errorExperiences) {
            dispatch(boundsActions.updateExperiences());
        }
    }, [isFetchingPointsOfInterest, isFetchingExperiences]);

    const updateMapData = (map) => {
        dispatch(placeActions.getMap(map));
    };
    return (
        <div className="h-full">
            <MapContainer
                center={[45.2412834, 27.9318981]}
                zoom={13}
                style={{ height: "100%", width: "100%" }}
                zoomControl={false}
                ref={updateMapData}
            >
                <ZoomControl position="bottomright" />
                <TileLayer
                    ref={refMapMode}
                    url={mapMode ? v1map.url : v2map.url}
                />
                <UpdatePlacesAndBounds />
                <Markers />
                <CenterMap />
                <ModeMap />
                <LocationMarker />
            </MapContainer>
        </div>
    );
}

export default Map;
