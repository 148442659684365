import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import { useEffect, useState } from "react";

const locationfIcon = L.Icon.extend({
    options: {
        iconSize: [32, 32],
    },
});

const blueIcon = new locationfIcon({ iconUrl: "/placeholder/placeholder-blue.png" });
const MiniMapSearch = ({ latitude, longitude }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Obține lățimea inițială a ferestrei

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth); // Actualizează lățimea ferestrei la fiecare redimensionare
        };

        window.addEventListener("resize", handleResize); // Adaugă evenimentul de redimensionare

        return () => {
            window.removeEventListener("resize", handleResize); // Elimină evenimentul de redimensionare la dezmontarea componentei
        };
    }, []);
    return (
        <div className={windowWidth > 700 ? "h-40 w-60" : "hidden"}>
            {latitude && longitude ? (
                <MapContainer
                    dragging={false}
                    zoomControl={false}
                    center={[latitude, longitude]}
                    zoom={14}
                    scrollWheelZoom={false}
                    style={{ height: "100%", width: "100%" }}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={[latitude, longitude]} icon={blueIcon}></Marker>
                </MapContainer>
            ) : (
                "Nu exista coordonate"
            )}
        </div>
    );
};

export default MiniMapSearch;
