import React, { useRef, useState, useEffect } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import { Rating } from "primereact/rating";
import { Button } from "primereact/button";
import { Modal } from "antd";
import { Toast } from "primereact/toast";
import EditIcon from "@mui/icons-material/Edit";
import { useGetReviewQuery } from "../../../services/smartsenseApi";
import axios from "axios";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

function EditReview({ review, onUpdate }) {
    const inputRef = useRef(null);

    // folosite pentru afisarea imagini recenziei
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [imagesPreview, setImagesPreview] = useState([]);
    const [imagesURLs, setImagesURLs] = useState([]);
    const toast = useRef(null);
    const userData = getUserData();

    // folosit pentru afisarea mesajelor in suprapunere - toast
    const showSuccess = (detailValue) => {
        toast.current.show({ severity: "success", summary: "Success Message", detail: detailValue, life: 3000 });
    };

    const showError = (detailValue) => {
        toast.current.show({ severity: "error", summary: "Error Message", detail: detailValue, life: 3000 });
    };
    // incarcare in formik datele experientei care urmeaza sa fie editate
    useEffect(() => {
        if (review) {
            // Fields used in form
            formik.handleChange({ target: { name: "body", value: review.body || "" } });
            formik.handleChange({ target: { name: "score", value: review.score || 0 } });
            formik.handleChange({ target: { name: "approved", value: review.approved || false } });
            formik.handleChange({ target: { name: "experience_id", value: review.experience_id || 1 } });
            formik.handleChange({ target: { name: "user_id", value: review.user_id || 100 } });
            formik.handleChange({ target: { name: "images_upload", value: review.images || 0 } });
        }
    }, [review]);

    // incarcare imaginilor in formular - vizual
    useEffect(() => {
        if (imagesPreview.length < 1) return;
        const newImageUrls = [];
        imagesPreview.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
        setImagesURLs(newImageUrls);
    }, [imagesPreview]);

    // https://www.primefaces.org/primereact/formik/
    const formik = useFormik({
        initialValues: {
            body: "",
            score: null,
            approved: true,
            experience_id: review.experience_id,
            user_id: 1,
            images_upload: [],
        },
        validate: (data) => {
            let errors = {};

            if (!data.body) {
                errors.body = "Comment is required.";
            }

            if (data.body.length >= 3000) {
                errors.body = "You have exceeded the maximum number of 3000 characters";
            }

            if (!data.score) {
                errors.score = "Score is required.";
            }

            return errors;
        },
        onSubmit: (data) => {
            axios({
                method: "put",
                url: process.env.REACT_APP_API_URL + "/web/reviews/" + review.id,
                data: {
                    ...data,
                },
                headers: {
                    'Authorization': `Bearer ${userData.token}` 
                }
            })
                .then((response) => {
                    handleCancel();
                    showSuccess(response?.data?.message);
                    onUpdate(data);
                })
                .catch((error) => {
                    if (error.response) {
                        showError(error.response.status);
                    } else if (error.request) {
                        showError(error.request);
                    } else {
                        showError(error.message);
                    }
                });
        },
    });

    // functii folosite pentru fereastra modal creare recenzie
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    // functii folosite pentru afisare erori la fields - formik
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    // transformarea imaginilor in base64 si incarcarea lor in fieldul de images_upload al formului
    const customBase64Uploader = async (event) => {
        const fileReader = new FileReader();
        await fileReader.readAsDataURL(event.target.files[0]);
        setImagesPreview([...event.target.files]);
        fileReader.onload = () => {
            formik.handleChange({
                target: { name: "images_upload", value: [{ title: event.target.files[0].name, image: fileReader.result }] },
            });
        };
    };

    // resetare afisare imagini - vizual in formular
    const resetFileInput = () => {
        // inputRef.current.value = null;

        setImagesPreview([]);
        setImagesURLs([]);
    };

    // resetare afisare imagini - images_upload formik
    const resetImageField = () => {
        formik.handleChange({
            target: { name: "images_upload", value: [] },
        });

        setImagesPreview([]);
        setImagesURLs([]);
        inputRef.current.value = null;
    };

    return (
        <>
            <Toast ref={toast} />
            <div>
                <button className="my-2 hover:bg-[rgb(240,240,240)] py-1 px-2 rounded-lg" onClick={showModal}>
                    <EditIcon />
                    <span className="">Edit</span>
                </button>

                <Modal
                    title="Lasă o recenzie"
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={1000}
                    footer={[
                        <Button className="mx-1 p-button-sm p-button-outlined p-button-secondary" key="back" onClick={handleCancel}>
                            Inapoi
                        </Button>,
                    ]}>
                    <form onSubmit={formik.handleSubmit} className="p-fluid">
                        <div className="field">
                            <label htmlFor="score" className={classNames({ "p-error": isFormFieldValid("score") })}>
                                Evaluare*
                            </label>
                            <span className="p-float-label">
                                <Rating
                                    cancel={false}
                                    id="score"
                                    name="score"
                                    value={formik.values.score}
                                    onChange={formik.handleChange}
                                    autoFocus
                                    className={classNames({ "p-invalid": isFormFieldValid("score") })}
                                />
                            </span>

                            {getFormErrorMessage("score")}
                        </div>

                        <div className="mt-4 field">
                            <span className="p-float-label">
                                <InputTextarea
                                    id="body"
                                    name="body"
                                    value={formik.values.body}
                                    onChange={formik.handleChange}
                                    autoFocus
                                    className={classNames({ "p-invalid": isFormFieldValid("body") })}
                                />
                                <label htmlFor="body" className={classNames({ "p-error": isFormFieldValid("body") })}>
                                    Comentariu*
                                </label>
                            </span>
                            {getFormErrorMessage("body")}
                        </div>

                        <div>
                            <div className="mt-4 field">
                                <label htmlFor="images_upload" className={classNames({ "p-error": isFormFieldValid("images_upload") })}>
                                    Imagine*
                                </label>
                                <span className="p-float-label">
                                    <input type="file" id="images_upload" name="images_upload" onChange={customBase64Uploader} autoFocus />
                                </span>
                            </div>

                            <div className="grid grid-cols-1 mt-2">
                                {/* aiciinceput */}
                                {imagesPreview.length === 0
                                    ? review?.images.length !== 0 &&
                                      review?.images?.map((img, index) => {
                                          return (
                                              <img
                                                  className="object-cover w-full rounded-lg h-80"
                                                  key={index}
                                                  src={`${process.env.REACT_APP_STORAGE}/${img.url}`}
                                              />
                                          );
                                      })
                                    : imagesURLs.map((imageSrc, index) => (
                                          <img className="object-cover w-full rounded-lg h-80" key={index} src={imageSrc} />
                                      ))}
                                {/* aiciterminat */}
                            </div>
                            {imagesPreview.length !== 0 && (
                                <button
                                    // style={{ textAlign: "center", padding: "auto", border: "1px solid black" }}
                                    className="w-full py-2 mt-3 text-base font-medium text-white bg-red-500 rounded-md px-auto p-button-sm p-button-danger"
                                    type="button"
                                    onClick={resetImageField}>
                                    Sterge {imagesPreview.length === 1 ? "imaginea" : "imaginile"}
                                </button>
                            )}
                        </div>

                        <Button type="submit" onClick={resetFileInput} label="Trimite" className="mt-2" />
                    </form>
                </Modal>
            </div>
        </>
    );
}

export default EditReview;
