import React, { useState, useRef } from "react";
import { Modal, Button } from "antd";
import { Toast } from "primereact/toast";
import ImageIcon from "@mui/icons-material/Image";
import DeleteIcon from "@mui/icons-material/Delete";
import EditReview from "./EditReview";
import axios from "axios";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

function deleteReview(reviewId, toast) {
    // folosit pentru afisarea mesajelor in suprapunere - toast
    const showSuccess = (detailValue) => {
        toast.current.show({ severity: "success", summary: "Success Message", detail: detailValue, life: 3000 });
    };

    const showError = (detailValue) => {
        toast.current.show({ severity: "error", summary: "Error Message", detail: detailValue, life: 3000 });
    };

    axios({
        method: "delete",
        url: process.env.REACT_APP_API_URL + "/web/reviews/" + reviewId,
    })
        .then((response) => {
            showSuccess(response?.data?.message);
        })
        .catch((error) => {
            if (error.response) {
                showError(error.response.status);
            } else if (error.request) {
                showError(error.request);
            } else {
                showError(error.message);
            }
        });
}

function QuestionAnswer({ review }) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fullRevVis, setFullRevVis] = useState(review.body.length <= 250 ? true : false);
    const [stateReview, setStateReview] = useState(review);
    const userData = getUserData();
    const toast = useRef(null);

    // functii folosite pentru fereastra modal creare recenzie
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleReviewUpdate = (orice) => {
        setStateReview(orice);
    };

    return (
        <>
            <Toast ref={toast} />
            <div>
                <div className="flex flex-row items-center space-x-2">
                    <img className="object-cover rounded-full h-14 w-14" src="/profile/avatar.jpg" alt="" />
                    <div>
                        <h3 className="font-semibold">{review?.user_name}</h3>
                        <span className="text-sm font-light text-neutral-500">{stateReview.created_at}</span>
                    </div>
                </div>
                <div className="py-3">
                    {fullRevVis ? (
                        <p>{stateReview.body}</p>
                    ) : (
                        <div>
                            {stateReview.body.substr(0, 250)}...{" "}
                            <button
                                onClick={() => {
                                    setFullRevVis(!fullRevVis);
                                }}
                                className="font-semibold underline cursor-pointer">
                                citește mai mult
                            </button>
                        </div>
                    )}
                    <div className="flex">
                        {stateReview?.images?.[0]?.url !== undefined && (
                            <button className="my-2 hover:bg-[rgb(240,240,240)] py-1 px-2 rounded-lg" onClick={showModal}>
                                <ImageIcon />
                                <span className="">Imagine</span>
                            </button>
                        )}
                        {review?.user_id === userData?.user?.id && (
                            <div className="flex flex-row">
                                <EditReview onUpdate={handleReviewUpdate} review={review} />

                                <button
                                    className="my-2 hover:bg-[rgb(240,240,240)] py-1 px-2 rounded-lg"
                                    onClick={() => deleteReview(review?.id, toast)}>
                                    <DeleteIcon />
                                    <span className="">Delete</span>
                                </button>
                            </div>
                        )}

                        <Modal
                            title="Recenzie completă"
                            visible={isModalVisible}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            width={700}
                            footer={[
                                <Button className="mx-1 p-button-sm p-button-raised p-button-secondary" key="back" onClick={handleCancel}>
                                    Inapoi
                                </Button>,
                            ]}>
                            <div className="flex flex-row items-center space-x-2">
                                <img
                                    className="object-cover rounded-full h-14 w-14"
                                    src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2F736x%2F46%2F21%2F15%2F462115868a01b333cd3247e4a88ab3d7.jpg&f=1&nofb=1"
                                    alt={stateReview.user_name}
                                />
                                <div>
                                    <h3 className="font-semibold">{stateReview?.user_name}</h3>
                                    <span className="text-sm font-light text-neutral-500">{stateReview.created_at}</span>
                                </div>
                            </div>
                            <div className="py-3">
                                <p>{stateReview.body}</p>
                            </div>
                            <div className="">
                                <img
                                    className="object-contain w-full rounded-lg"
                                    src={`${process.env.REACT_APP_STORAGE}/${stateReview?.images?.[0]?.url}`}
                                    alt={stateReview?.images?.[0]?.title}
                                />
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>
        </>
    );
}

export default QuestionAnswer;
