import { configureStore } from "@reduxjs/toolkit";
import boundsSlice from "../slice/map/bounds-slice";
import placeSlice from "../slice/map/place-slice";
import { smartsenseApi } from "../services/smartsenseApi";
import listSlice from "../slice/map/list-slice";
import searchSlice from "../slice/search/sliceSearch.jsx";

export const store = configureStore({
    reducer: {
        [smartsenseApi.reducerPath]: smartsenseApi.reducer,
        bounds: boundsSlice.reducer,
        place: placeSlice.reducer,
        list: listSlice.reducer,
        search: searchSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: boundsSlice,
            },
            serializableCheck: false,
        }).concat(smartsenseApi.middleware),
});

export default store;
