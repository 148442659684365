import { useEffect, useState } from "react";
import DashboardMenu from "../DashboardMenu";
import Footer from "../Footer";
import Header from "../Header";
import { getApiFavoriteExperiencesOfUser } from "../../services/apiServices";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import axios from "axios";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

const FavoriteUser = () => {
    let userData = getUserData();
    const [data, setData] = useState(userData.user.favorites);

    const updateFavorite = (experienceId) => {
        let _userData = JSON.parse(JSON.stringify(userData));
        let favExperiences = _userData.user.favorites;
        let newData = [];

        Object.keys(_userData.user.favorites).forEach((favExperienceKey) => {
            if (
                typeof _userData.user.favorites[favExperienceKey] === "object" &&
                !Array.isArray(_userData.user.favorites[favExperienceKey]) &&
                _userData.user.favorites[favExperienceKey] !== null
            ) {
                newData.push(_userData.user.favorites[favExperienceKey].id);
            } else newData = _userData.user.favorites;
        });

        if (!newData.includes(experienceId)) {
            newData.push(experienceId);
        } else {
            newData.splice(newData.indexOf(experienceId), 1);
        }

        const i = favExperiences.findIndex((e) => e.id === experienceId);
        favExperiences.splice(i, 1);

        axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + "/web/users/" + userData.user.id + "/favorite-experiences",
            headers: {
                Authorization: "Bearer " + userData.token.split("|")[1],
            },
            data: {
                experiences_ids: newData,
            },
        })
            .then((response) => {
                setData(favExperiences);
                _userData.user.favorites = favExperiences;
                localStorage.setItem("userData", JSON.stringify(_userData));
                console.log(response?.data);
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.status);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log(error.message);
                }
            });
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="top-0 left-0 geodir-js-favorite_btn position-relative" onClick={() => updateFavorite(rowData.id)}>
                <div className="relative w-24 h-8 hover:cursor-pointer">
                    <div className="absolute w-8 h-8 text-2xl text-center bg-blue-700 rounded-full">
                        <div className="gmui gmui-heart favoriteheart"></div>
                    </div>
                    <div className="flex items-center justify-end w-24 h-8 px-3 ml-2 bg-blue-700 rounded-full save-text">Elimină</div>
                </div>
            </div>
        );
    };

    const activityTemplate = (rowData) => {
        const activeText = rowData.active ? "Activ" : "Inactiv";
        return <>{activeText}</>;
    };

    const dateTemplate = (rowData) => {
        const [datePart, timePart] = rowData.start_date.split(" ");
        return datePart;
    };

    return (
        <div className="bg-[rgb(239,243,248)] min-h-full">
            <Header />
            <div className="container box-border py-8 mx-auto my-10 bg-white rounded-lg newtheme">
                <DashboardMenu />
                <div className="col-md-9 col-sm-12">
                    <div className="dashboard-list-box fl-wrap">
                        <div className="dashboard-title fl-wrap">
                            <h3>Experiențe favorite</h3>
                        </div>
                        <DataTable
                            value={data}
                            paginator
                            responsiveLayout="stack"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Arată de la {first} la {last} din {totalRecords}"
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50]}>
                            <Column field="title" header="Denumire"></Column>
                            <Column field="active" header="Activitate" body={activityTemplate}></Column>
                            <Column field="start_date" header="Dată începere" body={dateTemplate}></Column>
                            <Column field="end_date" header="Dată sfarsire" body={dateTemplate}></Column>

                            <Column header="Acțiuni" body={actionTemplate}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default FavoriteUser;
