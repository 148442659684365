import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getApiPointOfInterest, getApiPointOfInterestOfUser } from "../../services/apiServices";
import HeaderPoi from "./view/Header";
import Submenu from "./view/Submenu";
import Gallery from "./view/Gallery";
import Description from "./view/Description";
import Tags from "../experience/view/Tags";
import HostedBy from "./view/HostedBy";
import Carousel from "./view/Carousel";
import Footer from "../Footer";
import Header from "../Header";
import MiniMapPOI from "../map/MiniMapPOI";
import Layout from "./view/Layout";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

const PointOfInterestView = () => {
    const { pointofinterestId } = useParams();
    const [userData, setUserData] = useState(getUserData());
    const [pointOfInterest, setPointOfInterest] = useState(null);
    const [pointOfInterestUser, setPointOfInterestUser] = useState(null);

    const [isFetchingPointOfInterest, setFetchingPointOfInterest] = useState(true);
    const [isFetchingpointOfInterestUser, setIsFecthingPointOfInterestUser] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const response = await getApiPointOfInterest(pointofinterestId);
            setFetchingPointOfInterest(response.success === true && response.error === false ? false : true);
            setPointOfInterest(response.data.data);

            if (pointOfInterest) {
                const response1 = await getApiPointOfInterestOfUser(pointOfInterest.created_by.id, userData.token.split("|")[1]);
                setIsFecthingPointOfInterestUser(response1.success === true && response1.error === false ? false : true);
                setPointOfInterestUser(response1.data);
            }
        };

        fetchData();
    }, [isFetchingPointOfInterest]);

    return (
        <div className="bg-[#f6f6f6] min-h-full">
            <Header />
            <HeaderPoi pointOfInterest={pointOfInterest} isFetchingPointOfInterest={isFetchingPointOfInterest} />
            <Submenu submenuType="Experiente" pointOfInterest={pointOfInterest} userData={userData} />
            <div className="container py-4">
                {!isFetchingPointOfInterest && (
                    <div className="flex flex-col space-x-0 space-y-5 md:space-x-5 md:space-y-0 md:flex-row">
                        <div className="flex flex-col flex-grow space-y-5 overflow-auto basis-2/3">
                            <Gallery pointOfInterest={pointOfInterest} isFetchingPointOfInterest={isFetchingPointOfInterest} />
                            <Description pointOfInterest={pointOfInterest} />
                            <Carousel pointofinterestId={pointofinterestId} pointOfInterest={pointOfInterest} />
                            <Layout title="Hartă">
                                <MiniMapPOI point_of_intrest_id={pointofinterestId} />
                            </Layout>
                        </div>
                        <div className="flex flex-col space-y-5 basis-1/3">
                            {/* {!isFetchingpointOfInterestUser && (
                                <HostedBy pointOfInterestUser={pointOfInterestUser} username={pointOfInterest.created_by.name} />
                            )}
                            <Tags /> */}
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default PointOfInterestView;
