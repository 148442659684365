import React, { useEffect, useState } from "react";

import { useGetTagsExperienceQuery, useGetAttractionsQuery } from "../../services/smartsenseApi";
import { useForm } from "antd/lib/form/Form";
import axios from "axios";
import Header from "../Header";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

function UserPointsofinterestTable() {
    const [pointsofinterest, setPointsofinterest] = useState([]);
    const [loading, setLoading] = useState(false);
    const userData = getUserData();

    useEffect(() => {
        setLoading(true);
        axios({
            method: "get",
            url: process.env.REACT_APP_API_URL + "/web/users/" + userData.user.id + "/points-of-interest",
            headers: {
                Authorization: `Bearer ${userData.token}`,
            },
        }).then(
            (response) => {
                setPointsofinterest(response.data.data);
                setLoading(false);
            },
            (error) => {
                console.log(error);
            }
        );
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const linkTemplate = (rowData) => {
        return (
            <div className="flex flex-col">
                <Link to={"/pointofinterest/" + rowData.id}>Vizualizează</Link>
                <Link to={"/pointofinterest/" + rowData.id + "/edit"}>Editează</Link>
            </div>
        );
    };

    return (
        <>
            <DataTable
                value={pointsofinterest}
                paginator
                responsiveLayout="stack"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Rezultatele {first} - {last} din {totalRecords}"
                rows={5}
                className="w-full"
                loading={loading}>
                <Column field="title" header="Denumire"></Column>
                {/* <Column field="description" header="Descriere" body={(rowData) => `${rowData.description.substring(0, 50)}...`}></Column> */}
                <Column field="address" header="Adresă"></Column>
                <Column field="phone_number" header="Telefon"></Column>
                <Column header="Acțiuni" body={linkTemplate}></Column>
            </DataTable>
        </>
    );
}

export default UserPointsofinterestTable;
