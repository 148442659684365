import { Skeleton } from "primereact/skeleton";
import { getApiReviewsOfExperience } from "../../../services/apiServices";
import { useEffect, useState } from "react";
import QuestionAnswer from "./QuestionAnswer";

const Reviews = ({ experienceId }) => {
    const [reviews, setReviews] = useState(null);
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const response = await getApiReviewsOfExperience(experienceId);
            setIsFetching(response.success === true && response.error === false ? false : true);
            setReviews(response.data);
        };

        fetchData();
    }, []);
    return (
        <div className="mb-4 border-b">
            <h1 className="mb-3 text-2xl">Recenzii</h1>

            <div name="section1" className="grid gap-8 mb-3 lg:grid-cols-2">
                {isFetching ? (
                    <>
                        <Skeleton width="w-full" height="5rem"></Skeleton>
                        <Skeleton width="w-full" height="5rem"></Skeleton>
                        <Skeleton width="w-full" height="5rem"></Skeleton>
                        <Skeleton width="w-full" height="5rem"></Skeleton>
                    </>
                ) : reviews?.data.length === 0 ? (
                    <h3>No Reviews</h3>
                ) : (
                    reviews?.data.map((review, index) => {
                        if (review.approved) return <QuestionAnswer key={index} review={review} />;
                    })
                )}
            </div>
        </div>
    );
};

export default Reviews;
