import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import { useGetCategoriesPointOfIntrestQuery, useGetTagsExperienceQuery } from "../services/smartsenseApi";
import { useSelector, useDispatch } from "react-redux";
import { boundsActions } from "../slice/map/bounds-slice";
import { classNames } from "primereact/utils";

function Filter() {
    const { data: dataCategories, isFetching: isFetchingCategory, error: errorCategory } = useGetCategoriesPointOfIntrestQuery();
    const { data: dataTags, isFetching: isFetchingTags, error: errorTags } = useGetTagsExperienceQuery();
    const [staticTags, setStaticTags] = useState([]);
    const [staticCategories, setStaticCategories] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const categories = dataCategories?.data;
        if (!isFetchingCategory && !errorCategory) {
            let temp = [];
            Object.keys(categories).forEach((category, index) => {
                temp.push({
                    id: categories[category].id,
                    category: categories[category].title,
                    // icon_url: categories[category].ìcon_url,
                    // color: categories[category].color,
                    status: false,
                });
            });
            setStaticCategories(temp);
        }
    }, [isFetchingCategory]);

    useEffect(() => {
        const tags = dataTags?.data;

        if (!isFetchingTags && !errorTags) {
            let temp = [];
            Object.keys(tags).forEach((tag, index) => {
                temp.push({
                    id: tags[tag].id,
                    tag: tags[tag].tag,
                    status: false,
                });
            });
            setStaticTags(temp);
        }
    }, [isFetchingTags]);

    function toggleStatusCategories(category) {
        let temp = [...staticCategories];
        temp.forEach((cat) => {
            if (cat.category === category) {
                cat.status = !cat.status;
            }
        });
        setStaticCategories(temp);
    }

    function toggleStatusTags(tag) {
        let temp = [...staticTags];
        temp.forEach((tagg) => {
            if (tagg.tag === tag) {
                tagg.status = !tagg.status;
            }
        });
        setStaticTags(temp);
    }

    function cleanStatusCategories() {
        let temp = [...staticCategories];
        temp.forEach((cat) => {
            if (cat.status === true) {
                cat.status = false;
            }
        });
        setStaticCategories(temp);
    }

    function cleanStatusTags() {
        let temp = [...staticTags];
        temp.forEach((tagg) => {
            if (tagg.status === true) {
                tagg.status = false;
            }
        });
        setStaticTags(temp);
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        dispatch(boundsActions.filterPlaces());
        dispatch(boundsActions.updateExperiences());
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleClearFilters = () => {
        cleanStatusCategories();
        cleanStatusTags();
        dispatch(boundsActions.getCategoriesPOI(staticCategories));
        dispatch(boundsActions.getTagsPOI(staticTags));
        dispatch(boundsActions.filterPlaces());
        setIsModalVisible(false);
    };

    return (
        <>
            <Button
                label="Filtre"
                icon="pi pi-sliders-h"
                className="mx-2 my-2 p-button-raised p-button-secondary p-button-text p-button-sm"
                onClick={showModal}
            />
            <Modal
                title="Filtrare"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button
                        className="mx-1 p-button-sm p-button-outlined p-button-secondary"
                        label="Curatare Filtre"
                        key="clear"
                        onClick={handleClearFilters}></Button>,
                    <Button
                        className="mx-1 p-button-sm p-button-outlined p-button-secondary"
                        label="Inapoi"
                        key="back"
                        onClick={handleCancel}></Button>,
                    <Button
                        className="mx-1 p-button-sm p-button-outlined p-button-info"
                        label="Filtrare"
                        key="filter"
                        onClick={handleOk}></Button>,
                ]}>
                <div className="space-y-8 ">
                    <div>
                        <h1 className="text-xl font-normal">Taguri</h1>

                        <div className="flex-wrap space-x-2 space-y-2">
                            {staticTags.length === 0 ? (
                                <Skeleton height="2.5rem" className="mb-2"></Skeleton>
                            ) : (
                                staticTags.map((tag, index) => {
                                    return (
                                        <Button
                                            key={index}
                                            onClick={(_) => {
                                                toggleStatusTags(tag.tag);
                                                dispatch(boundsActions.getTagsPOI(staticTags));
                                            }}
                                            className={classNames("capitalize p-button-secondary p-button-sm", {
                                                "p-button-raised": tag.status,
                                                "p-button-outlined": !tag.status,
                                            })}>
                                            {tag.tag}
                                        </Button>
                                    );
                                })
                            )}
                        </div>
                    </div>

                    <div>
                        <h1 className="text-xl font-normal">Categorii</h1>

                        <div className="flex-wrap space-x-2 space-y-2">
                            {staticCategories.length === 0 ? (
                                <Skeleton height="2.5rem" className="mb-2"></Skeleton>
                            ) : (
                                staticCategories.map((category, index) => {
                                    return (
                                        <Button
                                            key={index}
                                            onClick={(_) => {
                                                toggleStatusCategories(category.category);
                                                dispatch(boundsActions.getCategoriesPOI(staticCategories));
                                            }}
                                            className={classNames("capitalize p-button-secondary p-button-sm", {
                                                "p-button-raised": category.status,
                                                "p-button-outlined": !category.status,
                                            })}>
                                            {category.category}
                                        </Button>
                                    );
                                })
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default Filter;
