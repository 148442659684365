import { Rating } from "primereact/rating";
import styled from "styled-components";

const StyledRating = styled(Rating)`
    .pi-star-fill {
        color: #facc39 !important;
        font-size: 13px;
    }
    .pi-star {
        color: rgba(255, 255, 255, 0.4);
        font-size: 13px;
    }
`;

const tags = [
    {
        id: 1,
        title: "Unaugmented",
        created_at: "2022-07-21T07:14:51.000000Z",
        updated_at: "2022-07-21T07:14:51.000000Z",
        icon_url: "images/icons/experience_category/WxTipzqwoV.png",
        color: "#808080",
    },
];

const Header = ({ isFetchingExperience, experience, reviews, isFetchingReviews }) => {
    return (
        <div className="relative w-full">
            {!isFetchingExperience && experience.images.length !== 0 ? (
                <img
                    className="object-cover w-full min-h-[450px] max-h-[560px] h-1/3"
                    src={`${process.env.REACT_APP_STORAGE}/${experience.images[0].url}`}
                    alt={experience.images[0].title}
                />
            ) : (
                <img className="object-cover w-full min-h-[450px] max-h-[560px] h-1/3" src="/default/default-image.png" alt="" />
            )}

            <div className="absolute bottom-0 left-0 w-full h-full bg-gradient-to-t to-[rgba(6,27,65,0)] from-[rgba(6,27,65,0.95)] flex flex-col justify-end">
                <div className="container flex flex-col text-white">
                    <div className="w-12 h-px my-4 bg-white rounded-full" />
                    <div className="flex flex-col justify-between lg:flex-row">
                        <div className="flex flex-col">
                            {!isFetchingExperience ? <h1 className="text-4xl font-bold text-white">{experience.title}</h1> : "Se incarca"}
                            <div className="flex flex-row justify-start space-x-3">
                                <div className="flex space-x-1.5">
                                    <i className="text-blue-500 gmui gmui-map-marker" style={{ fontSize: "16px" }}></i>
                                    {!isFetchingExperience ? <span>{experience.location}</span> : "Se incarca"}
                                </div>
                                {/* <div className="flex space-x-1.5">
                                    <i className="text-blue-500 gmui gmui-phone" style={{ fontSize: "16px" }}></i>
                                    {!isFetchingExperience ? <span> +348194129412</span> : "Se incarca"}
                                </div> */}
                            </div>
                        </div>
                        <div
                            className="flex items-center h-16 space-x-5 p-1.5 rounded-full my-3"
                            style={{ boxShadow: "0px 0px 0px 4px rgba(255,255,255,0.2)" }}>
                            {!isFetchingExperience ? (
                                <>
                                    <div className="flex items-center justify-center w-14 h-14 bg-[#384F95] rounded-full text-xl font-bold">
                                        {experience.average_review}
                                    </div>

                                    <div className="flex flex-col leading-4">
                                        <StyledRating value={Math.floor(experience.average_review)} readOnly stars={5} cancel={false} />
                                        {!isFetchingReviews && <span className="text-xs">{reviews.length} reviews</span>}
                                    </div>
                                </>
                            ) : (
                                "Se incarca"
                            )}

                            <i className="gmui gmui-review text-slate-400" style={{ fontSize: "34px" }}></i>
                        </div>
                    </div>
                    <div className="w-full h-px my-4 rounded-full bg-slate-600" />
                    <div className="flex flex-col justify-center my-6 space-y-5 lg:justify-between lg:flex-row lg:space-y-0">
                        <div className="flex flex-row items-center space-x-5">
                            {!isFetchingExperience && (
                                <div className="flex flex-row items-center space-x-2">
                                    <div
                                        style={{ backgroundColor: experience.experience_category.color }}
                                        className={`rounded-full h-8 w-8 text-center`}>
                                        <img src={`${process.env.REACT_APP_STORAGE}/${experience.experience_category.icon_url}`} alt="" />
                                    </div>
                                    <span>{experience.experience_category.title}</span>
                                </div>
                            )}

                            <div
                                className="flex flex-row items-center space-x-2 bg-[#558CF5] px-2 rounded-full py-1"
                                style={{
                                    boxShadow: "0px 0px 0px 5px rgba(255, 255, 255, 0.2)",
                                }}>
                                <svg
                                    fill="white"
                                    strokeWidth="0"
                                    viewBox="0 0 24 24"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 11h2v2H7zm0 4h2v2H7zm4-4h2v2h-2zm0 4h2v2h-2zm4-4h2v2h-2zm0 4h2v2h-2z"></path>
                                    <path d="M5 22h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zM19 8l.001 12H5V8h14z"></path>
                                </svg>
                                <span>{experience?.start_date.split(" ")[0] + " - " + experience?.end_date.split(" ")[0]}</span>
                            </div>

                            {/* <div
                                className="flex items-center h-8 space-x-5 py-1.5 pl-2 pr-3 rounded-full bg-emerald-300"
                                style={{ boxShadow: "0px 0px 0px 4px rgba(255,255,255,0.2)" }}>
                                <i className=" gmui gmui-open-lock" style={{ fontSize: "16px" }}></i>
                                <span>Activ</span>
                            </div> */}
                        </div>

                        <div className="flex flex-row space-x-2">
                            {experience?.tags.length > 0 ? (
                                experience?.tags.map((tag) => (
                                    <a
                                        href={`/map?tags=${tag.id}`}
                                        key={tag.id}
                                        className="flex flex-row items-center space-x-2 text-white">
                                        <div style={{ backgroundColor: tag.color }} className="p-1.5 rounded-full ">
                                            {/* <img src={tag.icon_url} alt="" /> */}
                                            <img
                                                className="w-8 h-8"
                                                src={`${process.env.REACT_APP_STORAGE}/${tag.icon_url}`}
                                                alt={tag.tag}
                                            />
                                        </div>
                                        <span>{tag.tag}</span>
                                    </a>
                                ))
                            ) : (
                                <span className="h-11">Nu există tag-uri atașate acestei experiente</span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
