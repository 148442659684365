import React, { useState, useToggle } from "react";

import Header from "../Header";
import Place from "./Place";
import Map from "./FavoriteMap";
import Filter from "../Filter";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "primereact/button";
import { listActions } from "../../slice/map/list-slice";
import PlacesSkeleton from "../Skeleton/PlacesSkeleton";

import "./style.css";

function FavoriteMapComp() {
    const pois = useSelector((state) => state.bounds.places);
    const countPois = useSelector((state) => state.bounds.countPOI);
    const experiences = useSelector((state) => state.bounds.experiences);
    const countExp = useSelector((state) => state.bounds.countExperiences);
    const togglePOIEXP = useSelector((state) => state.list.togglePOIExp);

    const dispatch = useDispatch();

    function LoadingPlaces({ lengther, counter, values, valueString }) {
        console.log(lengther);
        console.log(counter);
        console.log(values);
        console.log(valueString);
        if (lengther === 0 && counter === true)
            return Array(10)
                .fill(1)
                .map((_, index) => <PlacesSkeleton key={index} />);
        else if (lengther === 0 && counter === false) return <p>Nu se află nimic în această zonă.</p>;
        else if (lengther !== 0 && counter === true)
            return values.map((place, index) => <Place place={place} key={index} linkTo={valueString} />);
    }

    return (
        <>
            <div className="flex flex-col h-full">
                <Header className="flex-none" />
                <div className="overflow-hidden grow ">
                    <div className="flex h-full">
                        <div className="basis-full md:basis-2/3 lg:basis-[42rem] px-4 overflow-y-scroll invisible-scrollbar">
                            <h2 style={{textAlign: "center"}} className="text-lg my-3">{togglePOIEXP ? "Experientele mele favorite" : "Punctele de interes ale experientelor favorite"}</h2>

                            <div className="flex flex-row justify-between">
                                <div className="my-3 font-semibold">
                                    {togglePOIEXP ? (experiences.length > 0 ? (experiences.length > 20 ? experiences.length + " de experiențe" : experiences.length + " experiențe") : "Nici o experiență") : (pois.length > 0 ? (pois.length > 20 ? pois.length + " de puncte de interes" : pois.length + " puncte de interes") : "Nici un punct de interes")}
                                </div>
                                <div>
                                    <Button
                                        className="mx-2 my-2 p-button-raised p-button-secondary p-button-text p-button-sm"
                                        onClick={() => dispatch(listActions.togglePoiExp())}
                                        label={togglePOIEXP ? "Arată punctele de interes" : "Arată experiențe"}></Button>
                                    <Filter />
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                                {togglePOIEXP ? (
                                    <>
                                        <LoadingPlaces
                                            lengther={experiences.length}
                                            counter={countExp}
                                            values={experiences}
                                            valueString={"experience"}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <LoadingPlaces
                                            lengther={pois.length}
                                            counter={countPois}
                                            values={pois}
                                            valueString={"pointofinterest"}
                                        />
                                    </>
                                )}
                            </div>
                        </div>

                        <div className="hidden md:basis-1/3 md:block lg:grow lg:block">
                            <Map />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FavoriteMapComp;
