import L from "leaflet";

const locationfIcon = L.Icon.extend({
    options: {
        iconSize: [32, 32],
    },
});

// https://www.flaticon.com/free-icon/location_3699561?term=placeholder&page=1&position=8&page=1&position=8&related_id=3699561&origin=tag

const greenIcon = new locationfIcon({ iconUrl: "/placeholder/placeholder-green.png" }),
    redIcon = new locationfIcon({ iconUrl: "/placeholder/placeholder-red.png" }),
    orangeIcon = new locationfIcon({ iconUrl: "/placeholder/placeholder-orange.png" }),
    blueIcon = new locationfIcon({ iconUrl: "/placeholder/placeholder-blue.png" }),
    yellowIcon = new locationfIcon({ iconUrl: "/placeholder/placeholder-yellow.png" }),
    indigoIcon = new locationfIcon({ iconUrl: "/placeholder/placeholder-indigo.png" }),
    blackIcon = new locationfIcon({ iconUrl: "/placeholder/placeholder-black.png" }),
    violetIcon = new locationfIcon({ iconUrl: "/placeholder/placeholder-violet.png" }),
    humanIcon = new locationfIcon({ iconUrl: "/placeholder/placeholder-human.png" }),
    homeIcon = new locationfIcon({ iconUrl: "/placeholder/home.png" });

function deg2rad(deg) {
    return deg * (Math.PI / 180);
}
export default class utils {
    static changeColor(category) {
        switch (category) {
            case "human":
                return humanIcon;

            case "home":
                return homeIcon;

            case "fauna":
                return indigoIcon;

            case "flora":
                return greenIcon;

            case "restaurants":
                return redIcon;

            case "accommodation":
                return orangeIcon;

            case "information":
                return blueIcon;

            case "architecture":
                return yellowIcon;

            case "fishing":
                return violetIcon;

            default:
                return blackIcon;
        }
    }

    static getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
        const R = 6371; // Radius of the earth in km

        const dLat = deg2rad(lat2 - lat1);
        const dLon = deg2rad(lon2 - lon1);

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c; // Distance in km
        return d;
    }
}
