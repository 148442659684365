import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import { Link } from "react-router-dom";

import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import "./style.css";
function Login() {
    const navigate = useNavigate();

    const [checked, setChecked] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const formik = useFormik({
        initialValues: {
            name: "",
            password: "",
        },

        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = "E-mail necesar.";
            }

            if (!data.password) {
                errors.password = "Parola necesara.";
            }

            return errors;
        },
        onSubmit: (data) => {
            axios({
                method: "post",
                url: process.env.REACT_APP_API_URL + "/login",
                data: {
                    email: data.name,
                    password: data.password,
                },
            }).then(
                (response) => {
                    let userData = response.data.data;
                    let today = new Date();
                    let _prevday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
                    let _nextday = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
                    userData.subscriptionFrom = _prevday;
                    userData.subscriptionTo = _nextday;

                    axios({
                        method: "get",
                        url: process.env.REACT_APP_API_URL + "/web/users/" + userData.user.id + "/favorite-experiences",
                        headers: {
                            Authorization: `Bearer ${userData.token}`,
                        },
                    }).then(
                        (response) => {
                            userData.user.favorites = response.data.data;

                            localStorage.setItem("userData", JSON.stringify(userData));
                            navigate("/");
                        },
                        (error) => {
                            alert("Eroare la experiente favorite");
                            console.log(error);
                        }
                    );
                },
                (error) => {
                    alert("Date de conectare invalide!");
                    console.log(error);
                }
            );
            formik.resetForm();
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <div className="bg-[#f6f6f6] h-full flex items-center justify-center">
            <div className="bg-white w-[500px] mx-auto rounded-lg">
                <div className="pl-4 bg-[#4E65A3] rounded-t-lg text-white py-3">
                    <div className="font-bold">
                        Bine ați venit pe <span className="text-[rgb(77,183,254)]">SMART</span>SENSE
                        <span className="text-[rgb(77,183,254)]">.</span>
                    </div>
                </div>

                <div className="mx-4">
                    <div className="my-3 space-y-3">
                        <div className="grid grid-cols-2">
                            <Link to="/login" className="space-y-3 text-black hover:cursor-pointer">
                                <div className="flex flex-row mt-4 space-x-2">
                                    <i className="text-sky-400 gmui gmui-login"></i>
                                    <span>Autentificare</span>
                                </div>
                                <div className="h-0.5 bg-sky-400"></div>
                            </Link>
                            <Link to="/register" className="space-y-3 text-black hover:cursor-pointer">
                                <div className="flex flex-row mt-4 space-x-2">
                                    <i className="text-sky-400 gmui gmui-add-user"></i>
                                    <span>Inregistrare</span>
                                </div>
                                <div className="h-px bg-neutral-200"></div>
                            </Link>
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit} className="space-y-6 text-[rgb(135,140,159)]">
                        {/* Name */}
                        <div className="space-y-2 row">
                            <label htmlFor="name" className={classNames("ml-1 font-semibold", { "p-error": isFormFieldValid("name") })}>
                                Adresă Email *
                            </label>
                            <div className="col-md-12">
                                <InputText
                                    id="name"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    autoFocus
                                    className={classNames("w-full", { "p-invalid": isFormFieldValid("name") })}
                                />
                            </div>
                            {getFormErrorMessage("name")}
                        </div>

                        <div className="space-y-2 row">
                            <label
                                htmlFor="password"
                                className={classNames("ml-1 font-semibold", { "p-error": isFormFieldValid("password") })}>
                                Parolă *
                            </label>
                            <div className="col-md-12">
                                <Password
                                    id="password"
                                    name="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    className={classNames("w-full", { "p-invalid": isFormFieldValid("password") })}
                                    feedback={false}
                                />
                            </div>

                            {getFormErrorMessage("password")}
                        </div>

                        <div>
                            <button
                                className="w-36 h-10 bg-[#384F95] rounded-md text-white px-4 flex items-center transition-transform duration-300 relative"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}>
                                <span>Conectare</span>
                                <i
                                    className={`text-xl text-sky-400 gmui gmui-arrow-right ${isHovered ? "ml-6" : "ml-3"}`}
                                    style={{ transition: "margin-left 300ms" }}></i>
                            </button>
                            {/* <div className="mt-3 float-btn" style={{ lineHeight: "2.75rem" }}>
                                Sau <a href="/register">completeaza formularul</a> pentru a crea un cont.
                            </div> */}
                            <div className="flex flex-row justify-between my-4">
                                <div className="space-x-2 field-checkbox">
                                    <Checkbox inputId="binary" checked={checked} onChange={(e) => setChecked(e.checked)} />
                                    <label className="font-semibold" htmlFor="binary ">
                                        Remember Me
                                    </label>
                                </div>
                                <Link className="text-[rgb(77,183,254)] font-semibold" to={"/"}>
                                    Ți-ai uitat parola?
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;
