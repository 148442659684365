import { useEffect, useState, useRef } from "react";

const DropdownUi = ({ options, onValueChanged, placeholder }) => {
    const [isShow, setIsShow] = useState(false);
    const [value, setValue] = useState({ category: placeholder ? placeholder : "Categorie", id: "null" });
    const [isChanged, setIsChanged] = useState(false);
    let dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsShow(false);
        }
    };

    const showList = () => {
        setIsShow(!isShow);
        return isShow;
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    useEffect(() => {
        if (isChanged) {
            onValueChanged(value);
        }
    }, [isChanged, value]);

    return (
        <div className="min-w-[12rem] relative my-1 box-border inline-block" ref={dropdownRef} onClick={showList}>
            <div
                className="text-black bg-white flex justify-between items-center border-x-2 border-neutral-300 px-4 h-10 cursor-pointer transition ease-linear delay-200 hover:bg-[#323741]"
                style={{ zIndex: 2 }}>
                <span className="text-black">{value.category}</span>
                <div
                    className={`w-0 h-0 border-l-[5px] border-l-transparent border-r-[5px] border-r-transparent border-t-[6px] border-t-black transition delay-100 ${
                        isShow && "rotate-180"
                    }`}></div>
            </div>

            {isShow && (
                <ul
                    className="z-10 absolute bg-white border border-neutral-300 drop-shadow-lg rounded-sm text-[#9fa5b5] top-12 left-1/2 w-full translate-x-[-50%] opacity-1"
                    style={{ zIndex: 1 }}>
                    {options.map((item) => {
                        return (
                            <li
                                key={item.id}
                                className="w-full px-3 py-1.5 bg-white cursor-pointer text-neutral-500 hover:!bg-neutral-200"
                                onClick={() => {
                                    setValue({ category: item.title, id: item.id });
                                    setIsChanged(true);
                                }}>
                                {item.title}
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};

export default DropdownUi;
