import React, { useEffect, useState } from "react";
import { useLocation, matchPath } from "react-router";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { searchActions } from "../slice/search/sliceSearch";
import axios from "axios";

import "./style.css";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};


const Header = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [current, setCurrent] = useState(window.location.pathname);
    const [userData, setUserData] = useState(getUserData());

    const [searchInput, setSerchInput] = useState("");
    const [clickedSearch, setClickedSearch] = useState(false);
    const [clickedMenu, setClickedMenu] = useState(false);

    const [showNotificationsList, setShowNotificationsList] = useState(false);

    const [experiences, setExperiences] = useState(undefined);
    const [pointsOfInterest, setPointsOfInterest] = useState(undefined);


    let _items = [];

    let today = new Date().toISOString();

    const handleClickSearch = function(data)
    {
        console.log(data);
    }

    return (
        <header className="main-header">

            <a href="/" className="logo-holder">
                <img src="https://dev.smartsense.ro/storage/logo2.png" alt="" />
                {/* <h1 className="mx-auto text-2xl">SmartSense</h1> */}
            </a>

            <div className="newlogoholder">
                <a href="http://europa.eu/" target="_blank" className="logo-holder ml-2 text-align:center newlogo"><img src="https://smartsense.altfactor.ro/wp-content/uploads/2022/01/ue.png" alt="" /><span className="hiddenmobile">europa.eu</span></a>
                <a href="http://gov.ro/" target="_blank" className="logo-holder ml-2 text-align:center newlogo"><img src="https://smartsense.altfactor.ro/wp-content/uploads/2022/01/gr.png" alt="" /><span className="hiddenmobile">gov.ro</span></a>
                <a href="http://www.fonduri-ue.ro/" target="_blank" className="logo-holder ml-2 text-align:center newlogo"><img src="https://smartsense.altfactor.ro/wp-content/uploads/2022/01/is.png" alt="" /><span className="hiddenmobile">fonduri-ue.ro</span></a>
            </div>


            {/* <div className="header-search_btn show-search-button" onClick={(e) => setClickedSearch(!clickedSearch)}>
                <i className="gmui gmui-search">
                </i>
                <span>Căutare</span>
            </div> */}
            <a href="/pointofinterest/create" className="add-list color-bg">Adaugă Punct de interes </a>
            {!userData && 
            <div className="lang-wrap account-wrap">
                <a href="/login" className="show-lang">
                    <span>
                        <i className="gmui gmui-login"></i>
                        <strong>Conectare</strong>
                    </span>
                </a>
            </div>}
            {userData &&
            <div className="lang-wrap account-wrap">
                <div className="show-lang">
                    <span>
                        <i className="gmui gmui-user"></i>
                        <strong>Contul meu
                            <i className="gmui gmui-caret-down arrlan"></i>
                        </strong>
                    </span>
                </div>
                <ul className="lang-tooltip lang-action no-list-style">
                    <li className={"color2hover " + (current == "/user/profile" ? "act": "") } ><a href="/user/profile"><i className="gmui gmui-chart-line"></i>Dashboard</a></li>
                    <li className={"color2hover " + (current == "/user/notifications" ? "act": "") } ><a href="/user/notifications"> <i className="gmui gmui-rss"></i> Notificări </a></li>
                    <li className={"color2hover " + (current == "/user/profile/edit" ? "act": "") } ><a href="/user/profile/edit"><i className="gmui gmui-user-edit"></i> Editează profilul</a></li>
                    <li className={"color2hover " + (current == "/user/pointsofinterest" ? "act": "") } ><a href="/user/pointsofinterest"><i className="gmui gmui-th-list"></i> Puncte de interes </a></li>
                    <li className={"color2hover " + (current == "/user/experiences" ? "act": "") } ><a href="/user/experiences"><i className="gmui gmui-th-list"></i> Experiențe </a></li>
                    <li className={"color2hover " + (current == "/user/favourite" ? "act": "") } ><a href="/user/favorite"><i className="gmui gmui-heart"></i> Favorite </a></li>
                    <li className={"color2hover " + (current == "/user/review" ? "act": "") } ><a href="/user/review"><i className="gmui gmui-star"></i> Review-uri </a></li>
                    <li className={"color2hover " + (current == "/experience/create" ? "act": "") } ><a href="/experience/create"><i className="gmui gmui-file-plus"></i> Adaugă o Experiență</a></li>
                    <li className={"color2hover " + (current == "/pointofinterest/create" ? "act": "") } ><a href="/pointofinterest/create"><i className="gmui gmui-file-plus"></i> Adaugă un punct de interes</a></li>
                    <li className="color2hover"><a href="/logout"><i className="gmui gmui-sign-out"></i> Deconectare</a></li>
                </ul>
            </div>}

            {/* <div className="lang-wrap">
                <div className="show-lang">
                    <span>
                        <i className="gmui gmui-globe-europe">
                        </i>
                        <strong>Ro</strong>
                    </span>
                    <i className="fa gmui-caret-down arrlan"></i>
                </div>
                <ul className="lang-tooltip lang-action no-list-style">
                    <li>
                        <a href="#" className="current-lan" data-lantext="Ro">Română</a>
                    </li>
                    <li>
                        <a href="#" data-lantext="En">English</a>
                    </li>
                    <li>
                        <a href="#" data-lantext="Fr">Français</a>
                    </li>
                    <li>
                        <a href="#" data-lantext="Es">Español</a>
                    </li>
                    <li>
                        <a href="#" data-lantext="De">Deutsch</a>
                    </li>
                </ul>
            </div> */}
            <div className="nav-button-wrap color-bg">
                <div className="nav-button" onClick={(e) => setClickedMenu(!clickedMenu)}>
                    <span>
                    </span>
                    <span>
                    </span>
                    <span>
                    </span>
                </div>
            </div>
            <div className={"nav-holder main-menu "+(clickedMenu ? "vismobmenu" : "")}>
                <nav>
                    <ul className="no-list-style">
                        <li>
                            <a href="/" className={(current == "/" ? "act-link": "") }>Acasă</a>
                        </li>
                        <li>
                            <a href="/map" className={(current == "/map" ? "act-link": "") }>Experiențe și Puncte de interes</a>
                        </li>
                        <li>
                            <a href="/sensors" className={(current == "/sensors" ? "act-link": "") }>Senzori</a>
                        </li>
                    </ul>
                </nav>
                <nav className={"menusb sliding-menu "+(clickedMenu ? "show" : "hide")}>
                    <div className="sliding-menu-wrapper">
                        <ul className="no-list-style menu-panel-root" id="menu-panel-x6732">
                            <li>
                                <a href="/" className={'nav '+(current == "/" ? "act-link": "") }>Acasă <i className="gmui gmui-home"></i></a>
                            </li>
                            <li>
                                <a href="/map" className={'nav '+(current == "/map" ? "act-link": "") }>Experiențe și Puncte de interes <i className="gmui gmui-map-marker"></i></a>
                            </li>
                            <li>
                                <a href="/sensors" className={'nav '+(current == "/sensors" ? "act-link": "") }>Senzori <i className="gmui gmui-sensor"></i></a>
                            </li>
                            {!userData && 
                            <li>
                                <a href="/login" className="nav">Conectare <i className="gmui gmui-login"></i></a>
                            </li>}
                            {userData && <>
                                <li className={(current == "/user/profile" ? "act": "") } ><a href="/user/profile"><i className="gmui gmui-chart-line"></i>Dashboard</a></li>
                                <li className={(current == "/user/notifications" ? "act": "") } ><a href="/user/notifications"> <i className="gmui gmui-rss"></i> Notificări </a></li>
                                <li className={(current == "/user/profile/edit" ? "act": "") } ><a href="/user/profile/edit"><i className="gmui gmui-user-edit"></i> Editează profilul</a></li>
                                <li className={(current == "/user/pointsofinterest" ? "act": "") } ><a href="/user/pointsofinterest"><i className="gmui gmui-th-list"></i> Puncte de interes </a></li>
                                <li className={(current == "/user/experiences" ? "act": "") } ><a href="/user/experiences"><i className="gmui gmui-th-list"></i> Experiențe </a></li>
                                <li className={(current == "/user/favourite" ? "act": "") } ><a href="/user/favorite"><i className="gmui gmui-heart"></i> Favorite </a></li>
                                <li className={(current == "/user/review" ? "act": "") } ><a href="/user/review"><i className="gmui gmui-star"></i> Review-uri </a></li>
                                <li className={(current == "/experience/create" ? "act": "") } ><a href="/experience/create"><i className="gmui gmui-file-plus"></i> Adaugă o Experiență</a></li>
                                <li className={(current == "/pointofinterest/create" ? "act": "") } ><a href="/pointofinterest/create"><i className="gmui gmui-file-plus"></i> Adaugă un punct de interes</a></li>
                                <li className=""><a href="/logout"><i className="gmui gmui-sign-out"></i> Deconectare</a></li>
                                </>
                            }
                        </ul>
                    </div>
                </nav>
                
            </div>
            {/* <div className={`header-search_container header-search showmobile ` + (clickedSearch ? "show" : "hide")}>
                <div className="header-search-input">
                    <label><i className="fal fa-keyboard"></i></label>
                    <input type="text" placeholder="What are you looking for ?" defaultValue="" /> 
                    <i className="gmui gmui-search mobileSearchButton" onClick={(e) => handleClickSearch("ceva")}></i>
                </div>
            </div> */}
        </header>
    );
};

export default Header;
