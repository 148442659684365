import React, { useEffect, useState, useToggle } from "react";

import Header from "../Header";
import Footer from "../Footer";
import Place from "./Place";
import Map from "./Map";

import SearchFilters from "../search/SearchFilters";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "primereact/button";
import { listActions } from "../../slice/map/list-slice";
import { boundsActions } from "../../slice/map/bounds-slice";
import PlacesSkeleton from "../Skeleton/PlacesSkeleton";
import { useLocation } from "react-router-dom";

import "./style.css";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

function MapComp() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    // Extrage parametrii
    const paramsObj = {};
    searchParams.forEach((value, key) => {
        paramsObj[key] = value;
    });

    const pois = useSelector((state) => state.bounds.places);
    const countPois = useSelector((state) => state.bounds.countPOI);
    const experiences = useSelector((state) => state.bounds.experiences);
    const countExp = useSelector((state) => state.bounds.countExperiences);
    const togglePOIEXP = useSelector((state) => state.list.togglePOIExp);
    const searchInput = useSelector((state) => state.search.searchInput);
    const expSearchResult = useSelector((state) => state.search.searchInputExp);
    const poiSearchResult = useSelector((state) => state.search.searchInputPoi);
    const [favoriteExpIds, setFavoriteExpIds] = useState([]);

    const dispatch = useDispatch();
    const userData = getUserData();

    useEffect(() => {
        if ("tags" in paramsObj) {
            dispatch(boundsActions.getTagsId(paramsObj.tags.split(",").map((tag) => parseInt(tag))));
            dispatch(boundsActions.filterPlaces());
            dispatch(boundsActions.updateExperiences());
        }
    }, []);

    useEffect(() => {
        if (userData !== null) {
            const favoriteExpIdsTemp = userData.user.favorites.map((experience) => experience.id);
            setFavoriteExpIds(favoriteExpIdsTemp);
        }
    }, []);

    function LoadingPlaces({ lengther, counter, values, valueString }) {
        if (lengther === 0 && counter === true)
            return Array(10)
                .fill(1)
                .map((_, index) => <PlacesSkeleton key={index} />);
        else if (lengther === 0 && counter === false) return <p>Nu se află nimic în această zonă.</p>;
        else if (lengther !== 0 && counter === true)
            return values.map((place, index) => <Place place={place} key={index} linkTo={valueString} favExpIds={favoriteExpIds} />);
    }

    return (
        <>
            <div className="relative h-full">
                <Header />
                <div className="h-full grow">
                    <div className="relative h-full">
                        <div className="h-full p-0 col-md-6">
                            <div className="h-full listing-item-container init-grid-items fl-wrap" id="lisfw">
                                <div className="container h-full">
                                    <div className="row nopadding">
                                        <div className="list-main-wrap-header fl-wrap anim_clw ">
                                            <div className="container headerfilterContainer">
                                                <div className="list-main-wrap-title">
                                                    <h2>
                                                        {togglePOIEXP ? experiences.length : pois.length}{" "}
                                                        {togglePOIEXP ? "Experiențe" : "Puncte de interes"}
                                                    </h2>
                                                </div>
                                                <div className="list-main-wrap-opt">
                                                    <div className="price-opt" onClick={() => dispatch(listActions.togglePoiExp())}>
                                                        <div className="price-opt-title">
                                                            <i className="gmui gmui-map-marker filterpin"></i>
                                                            {togglePOIEXP ? "Arată punctele de interes" : "Arată experiențele"}
                                                        </div>
                                                    </div>

                                                    <SearchFilters />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="h-full p-0 col-md-12 listing-container">
                                        {togglePOIEXP ? (
                                            <LoadingPlaces
                                                lengther={searchInput.length === 0 ? experiences.length : expSearchResult.length}
                                                counter={searchInput.length === 0 ? countExp : expSearchResult.length !== 0}
                                                values={searchInput.length === 0 ? experiences : expSearchResult}
                                                valueString={"experience"}
                                            />
                                        ) : (
                                            <LoadingPlaces
                                                lengther={searchInput.length === 0 ? pois.length : poiSearchResult.length}
                                                counter={searchInput.length === 0 ? countPois : poiSearchResult.length !== 0}
                                                values={searchInput.length === 0 ? pois : poiSearchResult}
                                                valueString={"pointofinterest"}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-0 col-md-6 mapcontainer">
                            <Map />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MapComp;
