import { createSlice } from "@reduxjs/toolkit";

const placeSlice = createSlice({
    name: "place",
    initialState: {
        map: {},
        userLocation: [],
        placeData: null,
    },
    reducers: {
        getMap(state, action) {
            state.map = action.payload;
        },
        getUserLocation(state, action) {
            state.userLocation = action.payload;
        },
    },
});

export const placeActions = placeSlice.actions;

export default placeSlice;
