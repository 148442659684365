import { Skeleton } from "primereact/skeleton";
import PreviewImage1 from "../../previewimages/PreviewImage1";
import PreviewImage3 from "../../previewimages/PreviewImage3";
import PreviewImage4 from "../../previewimages/PreviewImage4";
import PreviewImage5 from "../../previewimages/PreviewImage5";

const Gallery = ({ experience, isFetchingExperience }) => {
    function ChooseImagePreview(props) {
        if (props.value.length === 0)
            return (
                <img
                    className="object-cover w-[100%] h-[60vh] min-h-[20rem] max-h-[42rem] rounded-lg"
                    src="/default/default-image.png"
                    alt=""
                />
            );
        else if (props.value.length === 1 || props.value.length === 2) return <PreviewImage1 images={props.value} />;
        else if (props.value.length === 3) return <PreviewImage3 images={props.value} />;
        else if (props.value.length === 4) return <PreviewImage4 images={props.value} />;
        else if (props.value.length > 4) return <PreviewImage5 images={props.value} />;
    }

    return (
        <div>
            {!isFetchingExperience && experience?.images !== undefined ? (
                <ChooseImagePreview value={experience?.images} />
            ) : (
                <Skeleton width="w-full" height="35rem"></Skeleton>
            )}
        </div>
    );
};

export default Gallery;
