import React from "react";

function PreviewImage1({ images }) {
    const array_images = images;

    return (
        <div className="flex flex-row gap-2 h-[60vh] min-h-[20rem] max-h-[42rem]">
            <img
                className="object-cover w-[100%] h-full rounded-2xl"
                src={`${process.env.REACT_APP_STORAGE}/${array_images[0].url}`}
                alt={array_images[0].title}
            />
        </div>
    );
}

export default PreviewImage1;
