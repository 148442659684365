import axios from "axios";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useState } from "react";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

const Submenu = ({ submenuType, pointOfInterest }) => {
    // const [userData, setUserData] = useState(getUserData());
    // const do_favorite = (experience_id) => {
    //     let favExperiences = [];
    //     let objects = true;
    //     let _userData = JSON.parse(JSON.stringify(userData));

    //     if (!userData) {
    //         // setShowLogin(true);
    //         alert("Pentru a putea adauga o experienta la favorite este necesar sa va inregistrati");
    //         return;
    //     }
    //     Object.keys(_userData.user.favorites).forEach((favExperienceKey) => {
    //         if (
    //             typeof _userData.user.favorites[favExperienceKey] === "object" &&
    //             !Array.isArray(_userData.user.favorites[favExperienceKey]) &&
    //             _userData.user.favorites[favExperienceKey] !== null
    //         ) {
    //             favExperiences.push(_userData.user.favorites[favExperienceKey].id);
    //         } else favExperiences = _userData.user.favorites;

    //         objects = false;
    //     });

    //     if (favExperiences.includes(experience_id)) {
    //         var index = favExperiences.indexOf(experience_id);
    //         if (index !== -1) {
    //             favExperiences.splice(index, 1);
    //         }
    //     } else {
    //         favExperiences.push(experience_id);
    //     }

    //     _userData.user.favorites = favExperiences;
    //     localStorage.setItem("userData", JSON.stringify(_userData));

    //     axios({
    //         method: "post",
    //         url: process.env.REACT_APP_API_URL + "/web/users/" + _userData.user.id + "/favorite-experiences",
    //         data: {
    //             experiences_ids: favExperiences,
    //         },
    //         headers: {
    //             Authorization: "Bearer " + _userData.token.split("|")[1],
    //         },
    //     }).then(
    //         (response) => {
    //             console.log(response);
    //         },
    //         (error) => {
    //             alert("Eroare la experiente favorite");
    //             console.log(error);
    //         }
    //     );
    //     setUserData(_userData);
    // };
    return (
        <div className="w-full bg-white" id="galleryPoi">
            <div className="container">
                <div className="flex flex-col items-start justify-center py-3 space-y-3 lg:items-center lg:justify-between lg:flex-row lg:space-y-0">
                    <div className="flex flex-col space-x-0 space-y-3 font-bold sm:space-x-7 text-slate-500 text-md sm:flex-row sm:space-y-0">
                        <a
                            href="#galleryPoi"
                            className="flex flex-row items-center pr-4 space-x-2 border-r border-transparent sm:border-slate-400 text-[#7d93b2]">
                            <i className="gmui gmui-gallery" style={{ fontSize: "18px" }}></i>
                            <span>Top</span>
                        </a>
                        <a
                            href="#descriptionPoi"
                            className="flex flex-row items-center pr-8 space-x-1 border-r border-transparent sm:border-slate-400 text-[#7d93b2]">
                            <i className="gmui gmui-info" style={{ fontSize: "18px" }}></i>
                            <span>Detalii</span>
                        </a>
                        <a href="#carouselPoi" className="flex flex-row items-center pr-8 space-x-2 text-[#7d93b2]">
                            <i className="gmui gmui-image-view" style={{ fontSize: "18px" }}></i>
                            <span>{submenuType}</span>
                        </a>
                    </div>
                    {/* <button
                        onClick={() => do_favorite(experience?.id)}
                        className="flex items-center space-x-2 bg-[#425998] py-1.5 px-3 rounded-lg">
                        {userData?.user ? (
                            userData.user.favorites.includes(experience?.id) ? (
                                <>
                                    <FavoriteBorderIcon sx={{ color: "red" }} fontSize="small" />
                                    <span className="font-semibold text-white">Elimina Favorit</span>
                                </>
                            ) : (
                                <>
                                    <FavoriteBorderIcon sx={{ color: "rgb(94,203,94)" }} fontSize="small" />
                                    <span className="font-semibold text-white">Adauga Favorit</span>
                                </>
                            )
                        ) : (
                            <>
                                <FavoriteBorderIcon fontSize="small" />
                                <span>Adauga Favorit</span>
                            </>
                        )}
                    </button> */}
                </div>
            </div>
        </div>
    );
};

export default Submenu;
