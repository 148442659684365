import Explore from "../homepage/Explore";
import LatestListing from "../homepage/LatestListing";
import HowItWorks from "../homepage/HowItWorks";
import OurApp from "../homepage/OurApp";
import Footer from "../Footer";
import Header from "../Header";

const HomePage = () => {
    return (
        <div className="min-h-full bg-white">
            <Header />
            <div className="flex flex-col">
                <Explore />
                <LatestListing />
                <OurApp />
                <HowItWorks />
            </div>
            <Footer />
        </div>
    );
};

export default HomePage;
