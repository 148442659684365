import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_API_URL;

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};
let userData = getUserData();

const createRequest = (url) => ({ url });
var token = userData?.token ? userData.token : "none";

export const smartsenseApi = createApi({
    reducerPath: "smartsenseApi",
    baseQuery: fetchBaseQuery({
        prepareHeaders: async (headers) => {
            headers.set("Content-Type", "application/json");
            headers.set("Access-Control-Allow-Origin", "*");
            headers.set("Authorization", "Bearer " + token);
            return headers;
        },
        baseUrl: baseUrl
    }),
    endpoints: (builder) => ({
        getAttractions: builder.query({
            query: () => createRequest("/mobile/points-of-interest"),
        }),
        getAttractionsWeb: builder.query({
            query: () => createRequest("/web/points-of-interest"),
        }),
        getAttraction: builder.query({
            query: (pointofinterestId) => createRequest(`/web/points-of-interest/${pointofinterestId}`),
        }),
        getExperiences: builder.query({
            query: () => createRequest("/mobile/experiences"),
        }),
        getExperiencesWeb: builder.query({
            query: () => createRequest("/web/experiences"),
        }),
        getExperience: builder.query({
            query: (experienceId) => createRequest(`/web/experiences/${experienceId}`),
        }),
        getExperienceOfPointOfIntrest: builder.query({
            query: (pointofinterestId) => createRequest(`/web/points-of-interest/${pointofinterestId}/experiences`),
        }),
        getPointsOfIntrestOfExperience: builder.query({
            query: (experienceId) => createRequest(`/web/experiences/${experienceId}/points-of-interest`),
        }),
        getPointsOfIntrestOfUser: builder.query({
            query: (userId) => createRequest(`/web/users/${userId}/points-of-interest`),
        }),
        getCategoriesPointOfIntrest: builder.query({
            query: () => createRequest("/web/point-of-interest-categories"),
        }),
        getCategoriesExperience: builder.query({
            query: () => createRequest("/web/experience-categories"),
        }),
        getTagsExperience: builder.query({
            query: () => createRequest("/web/tags"),
        }),
        getReview: builder.query({
            query: (reviewId) => createRequest(`/web/reviews/${reviewId}`),
        }),
        getReviewsExperience: builder.query({
            query: (experienceId) => createRequest(`/web/experiences/${experienceId}/reviews`),
        }),
        getUserExperiences: builder.query({
            query: (userId) => createRequest(`/web/users/${userId}/experiences`),
        }),
        getUserExperiencesMobile: builder.query({
            query: (userId) => createRequest(`/mobile/users/${userId}/experiences`),
        }),
        getUserOwnExperiences: builder.query({
            query: (userId) => createRequest(`/web/users/${userId}/my-experiences`),
        }),
        getUserOwnExperiencesMobile: builder.query({
            query: (userId) => createRequest(`/mobile/users/${userId}/my-experiences`),
        }),
        getStations: builder.query({
            query: () => createRequest(`/web/stations`),
        }),
        getStationSensors: builder.query({
            query: (stationid) => createRequest(`/web/stations/${stationid}/sensor-values`),
        }),
        getFavoriteUserExperiences: builder.query({
            query: (userId) => createRequest(`/web/users/${userId}/favorite-experiences`),
        }),
        getUserNotificationTopics: builder.query({
            query: (userId) => createRequest(`/web/users/${userId}/notification-topics`),
        }),
        getNotificationTopics: builder.query({
            query: () => createRequest(`/web/notifications/topics`),
        }),
        deleteUser: builder.mutation({
            query: (id) => ({
                url: `/web/users`,
                method: "DELETE",
                body: {
                    user_id: id,
                },
            }),
        }),
    }),
});

export const {
    useGetAttractionsQuery,
    useGetAttractionsWebQuery,
    useGetAttractionQuery,
    useGetExperiencesQuery,
    useGetExperiencesWebQuery,
    useGetExperienceQuery,
    useGetExperienceOfPointOfIntrestQuery,
    useGetPointsOfIntrestOfExperienceQuery,
    useGetPointsOfIntrestOfUserQuery,
    useGetCategoriesPointOfIntrestQuery,
    useGetCategoriesExperienceQuery,
    useGetTagsExperienceQuery,
    useGetReviewQuery,
    useGetReviewsExperienceQuery,
    useGetUserExperiencesQuery,
    useGetUserExperiencesMobileQuery,
    useGetUserOwnExperiencesQuery,
    useGetUserOwnExperiencesMobileQuery,
    useGetStationsQuery,
    useGetStationSensorsQuery,
    useGetFavoriteUserExperiencesQuery,
    useGetUserNotificationTopicsQuery,
    useGetNotificationTopicsQuery,
    useDeleteUserMutation,
} = smartsenseApi;
