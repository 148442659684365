import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DropdownUI from "../UI/DropdownUI";
import axios from "axios";
import { getApiTags, getApiExperienceCategories, getApiPOICategories } from "../../services/apiServices";

import { MapContainer, TileLayer, Marker, Popup, useMap, ZoomControl, CircleMarker } from "react-leaflet";
import MiniMapSearch from "./MiniMapSearch";

const optionsSearch = [
    { title: "Experiență", id: "expID" },
    { title: "Puncte de interes", id: "poiID" },
];

const Explore = () => {
    const [scrollY, setScrollY] = useState(0);
    const [valueCategoryExperience, setValueCategoryExperience] = useState(null);
    const [valueCategoryPOI, setValueCategoryPOI] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [searchType, setSearchType] = useState({ category: "Puncte de interes", id: "poiID" });
    const [isFetchingTags, setIsFetchingTags] = useState(true);
    const [isFetchingExperienceCategories, setIsFetchingExperienceCategories] = useState(true);
    const [isFetchingPOICategories, setIsFetchingPOICategories] = useState(true);
    const [tags, setTags] = useState(null);
    const [experienceCategories, setExperienceCategories] = useState(null);
    const [POICategories, setPOICategories] = useState(null);
    const [itemSearch, setItemSearch] = useState([]);

    const [showModal, setShowModal] = useState(false);

    const closeModal = () => {
        setShowModal(false);
    };

    const openModal = () => {
        setShowModal(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await getApiTags();
            setIsFetchingTags(response.success === true && response.error === false ? false : true);
            setTags(response.data);

            const response1 = await getApiExperienceCategories();
            setIsFetchingExperienceCategories(response1.success === true && response1.error === false ? false : true);
            setExperienceCategories(response1.data);

            const response2 = await getApiPOICategories();
            setIsFetchingPOICategories(response2.success === true && response2.error === false ? false : true);
            setPOICategories(response2.data);
        };

        fetchData();
    }, []);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         setScrollY(window.scrollY);
    //     };

    //     window.addEventListener("scroll", handleScroll);

    //     return () => {
    //         window.removeEventListener("scroll", handleScroll);
    //     };
    // }, []);

    // pentru dropdown categorie
    const handleValueChangeExperienceCategory = (newValue) => {
        setValueCategoryExperience(newValue);
    };

    const handleValueChangePOICategory = (newValue) => {
        setValueCategoryPOI(newValue);
    };

    const handleTypeChange = (newValue) => {
        setSearchType(newValue);
    };

    // pentru input search
    const handleInputChange = (event) => {
        setSearchValue(event.target.value);
    };

    const fetchDataSearch = async () => {
        const url =
            searchType.id === "expID"
                ? "https://dev.smartsense.ro/api/web/experiences"
                : "https://dev.smartsense.ro/api/web/points-of-interest";

        const params = {};

        if (searchValue.length > 2) {
            params.keyword = searchValue;
        }

        if (valueCategoryExperience !== null) {
            params.experience_category_ids = [valueCategoryExperience.id];
        }

        if (valueCategoryPOI !== null) {
            params.category_ids = [valueCategoryPOI.id];
        }

        console.log("url", url, "params", params);

        try {
            const response = await axios.get(url, {
                params: params,
            });
            setItemSearch(response.data.data);
            console.log(response.data); // Aici vei avea datele primite de la API
            openModal();
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const position = [51.505, -0.09];
    return (
        <div className="relative w-full overflow-hidden text-white">
            <img
                className="object-cover w-screen md:min-h-[855px] max-h-[855px] min-h-[1040px]"
                src="delta/headerhome.jpg"
                alt=""
                style={{ transform: `translateY(-${scrollY / 7}px)` }}
            />
            <div className="absolute top-0 left-0 flex flex-col items-center pt-10 w-full h-full mx-auto bg-[rgba(0,0,0,0.4)]">
                <div className="w-12 rounded-full h-0.5 bg-[#4DB7FE] mb-8" />
                <h1 className="text-4xl font-bold text-center text-white">Exploreză cele mai frumoase zone și experiente</h1>
                <p className="mx-2 text-sm font-semibold">
                    Găsiți câteva dintre cele mai bune sfaturi din jurul orașului de la partenerii și prietenii noștri.
                </p>
                <div className=" mb-3 mt-5 bg-[rgba(212,212,212,0.4)] text-black rounded-lg p-2 relative">
                    <div className="flex flex-col w-full bg-white rounded-md md:flex-row">
                        <input
                            className="px-4 py-2.5 border-b shadow-none outline-none sm:rounded-l-md rounded-t-md sm:my-0 border-b-slate-400 sm:border-b-transparent "
                            type="text"
                            placeholder="Ce cauți?"
                            value={searchValue}
                            onChange={handleInputChange}
                        />

                        <DropdownUI options={optionsSearch} onValueChanged={handleTypeChange} placeholder="Puncte de interes" />

                        {/* {!isFetchingExperienceCategories && (
                            <DropdownUI
                                options={searchValue.id === "expID" ? experienceCategories : POICategories}
                                onValueChanged={
                                    searchValue.id === "expID" ? handleValueChangeExperienceCategory : handleValueChangePOICategory
                                }
                                placeholder="Categorie"
                            />
                        )} */}

                        {searchType.id === "expID" && !isFetchingExperienceCategories && (
                            <DropdownUI options={experienceCategories} onValueChanged={handleValueChangeExperienceCategory} />
                        )}

                        {searchType.id === "poiID" && !isFetchingPOICategories && (
                            <DropdownUI options={POICategories} onValueChanged={handleValueChangePOICategory} />
                        )}

                        <button
                            onClick={fetchDataSearch}
                            className="w-56  bg-[#384F95] mx-2 rounded-md text-white my-1 flex flex-row items-center justify-evenly">
                            <i className="text-lg gmui gmui-search"></i>
                            <span>Caută</span>
                        </button>
                    </div>

                    {showModal && (
                        <div className="absolute transform -translate-x-1/2 top-[194px] md:top-20 left-1/2">
                            <div className="bg-[rgba(212,212,212,0.8)] min-w-[390px] max-w-[600px] max-h-[57vh] overflow-scroll px-3 rounded-lg">
                                <div className="flex flex-row justify-between my-1">
                                    <span className="font-medium"> Rezultatele căutării:</span>
                                    <button onClick={() => closeModal()} className="font-medium">
                                        Inchide
                                    </button>
                                </div>
                                {itemSearch.length === 0
                                    ? "Nu se găsesc rezultate..."
                                    : itemSearch.map((item) => {
                                          return (
                                              <div className="flex flex-row my-3 space-x-3" key={item.id}>
                                                  <div className="relative h-40 mx-auto w-80">
                                                      <img
                                                          className="object-cover w-[380px] h-full rounded-md"
                                                          src={
                                                              item?.images[0]?.url
                                                                  ? `${process.env.REACT_APP_STORAGE}/${item.images[0].url}`
                                                                  : "default/default-image.png"
                                                          }
                                                          alt={item?.images[0]?.title ? item.images[0]?.title : "default-image.png"}
                                                      />

                                                      <div className="absolute bottom-0 left-0 w-full h-40 bg-gradient-to-t to-[rgba(6,27,65,0)] from-[rgba(6,27,65,0.95)] rounded-md"></div>

                                                      <div className="absolute top-0 left-0 flex flex-col justify-end w-full h-full px-3 pt-4 text-white ">
                                                          {/* upper div */}

                                                          {/* bottom div */}
                                                          <div className="flex flex-col justify-end ">
                                                              <div className="flex flex-row justify-between my-2">
                                                                  <div>
                                                                      <Link
                                                                          to={
                                                                              searchType.id === "expID"
                                                                                  ? `/experience/${item.id}`
                                                                                  : `/pointofinterest/${item.id}`
                                                                          }
                                                                          className="font-bold text-white ">
                                                                          {item.title}
                                                                      </Link>
                                                                      <div className="flex flex-row items-center space-x-1">
                                                                          <i className="text-lg gmui gmui-map-marker text-sky-400"></i>
                                                                          <span className="text-sm">
                                                                              {searchType.id === "expID"
                                                                                  ? item.location
                                                                                      ? item.location
                                                                                      : "Locație necunoscută"
                                                                                  : item.address
                                                                                  ? item.address
                                                                                  : "Locație necunoscută"}
                                                                          </span>
                                                                      </div>
                                                                      <span>{item.description.substring(0, 35) + "..."}</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  {searchType.id === "poiID" && item.latitude && item.longitude && (
                                                      <MiniMapSearch latitude={item.latitude} longitude={item.longitude} />
                                                  )}
                                              </div>
                                          );
                                      })}
                            </div>
                        </div>
                    )}
                </div>

                <div className="w-12 h-px my-4 bg-white rounded-full" />
                <p className="mx-2 text-sm font-semibold">Ești în cautare de ceva? Utilizați căutarea rapidă după tags</p>
                <div className="grid grid-cols-3 mx-2 mt-4 md:grid-cols-4 lg:grid-cols-8 gap-x-16 gap-y-4 lg:gap-y-0">
                    {!isFetchingTags &&
                        tags.map((tag) => (
                            <a href={`/map?tags=${tag.id}`} key={tag.id} className="flex flex-col items-center space-y-2">
                                <img
                                    className="object-cover h-14 w-14"
                                    src={`${process.env.REACT_APP_STORAGE}/${tag?.icon_url}`}
                                    alt={tag.tag}
                                />
                                <span className="font-semibold text-white">{tag.tag}</span>
                            </a>
                        ))}
                </div>
                <div className="mt-5 mb-2 text-center">
                    <h3 className="mb-2 text-lg font-bold text-white">Descopera mai multe</h3>
                    <Link to={"/map"} className="px-3 text-black bg-white py-1.5 rounded-lg">
                        Hartă
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Explore;
