import React, { useEffect, useState } from "react";

import { useGetTagsExperienceQuery, useGetAttractionsQuery } from "../../services/smartsenseApi";
import { useForm } from "antd/lib/form/Form";
import axios from "axios";
import Header from "../Header";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

function UserExperiencesTable() {
    const [experiences, setExperiences] = useState([]);
    const [loading, setLoading] = useState(false);
    const userData = getUserData();

    useEffect(() => {
        setLoading(true);
        axios({
            method: "get",
            url: process.env.REACT_APP_API_URL + "/mobile/users/" + userData.user.id + "/my-experiences",
            headers: {
                Authorization: `Bearer ${userData.token}`,
            },
        }).then(
            (response) => {
                setExperiences(response.data.data);
                setLoading(false);
            },
            (error) => {
                console.log(error);
            }
        );
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const linkTemplate = (rowData) => {
        return (
            <div className="flex flex-col">
                <Link to={"/experience/" + rowData.id}>Vizualizează</Link>
                <Link className="" to={"/experience/" + rowData.id + "/edit"}>
                    Editează
                </Link>
            </div>
        );
    };

    return (
        <DataTable
            value={experiences}
            paginator
            responsiveLayout="stack"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Rezultatele {first} - {last} din {totalRecords}"
            rows={5}
            className="w-full"
            loading={loading}>
            <Column field="title" header="Denumire"></Column>
            {/* <Column field="description" header="Descriere" body={(rowData) => `${rowData.description.substring(0, 50)}...`}></Column> */}
            <Column field="location" header="Adresă"></Column>
            <Column
                field="average_review"
                header="Scor recenzie"
                body={(rowData) => parseFloat(rowData.average_review).toFixed(1)}></Column>
            <Column header="Acțiuni" body={linkTemplate}></Column>
        </DataTable>
    );
}

export default UserExperiencesTable;
