import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { useSelector, useDispatch } from "react-redux";
import { boundsActions } from "../../slice/map/bounds-slice";

import {
    useGetCategoriesExperienceQuery,
    useGetCategoriesPointOfIntrestQuery,
    useGetTagsExperienceQuery,
} from "../../services/smartsenseApi";

function SearchFilters() {
    let [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();

    const tagsIDsRedux = useSelector((state) => state.bounds.tagsIDs);

    const [newTags, setNewTags] = useState([]);
    const [newCategoriesExperiences, setNewCategoriesExperiences] = useState([]);
    const [newCategoriesPointsOfInterest, setNewCategoriesPointsOfInterest] = useState([]);

    // console.log("AAAAAAAAAAAAAAAAAAAAAAAAAA");
    // console.log(newTags, "newTags");
    // console.log(newCategoriesExperiences, "newCategoriesExperiences");
    // console.log(newCategoriesPointsOfInterest, "setNewCategoriesPointsOfInterest");

    const [newTagsIds, setNewTagsIds] = useState([]);
    const [newCategoriesExperiencesIds, setNewCategoriesExperiencesIds] = useState([]);
    const [newCategoriesPointsOfInterestIds, setNewCategoriesPointsOfInterestIds] = useState([]);

    const { data: tags, isFetching: isFetchingTags, error: errorTags } = useGetTagsExperienceQuery();
    const {
        data: categoriesExperience,
        isFetching: isFetchingCategoriesExperience,
        error: errorCategoriesExperience,
    } = useGetCategoriesExperienceQuery();
    const {
        data: categoriesPointsOfInterest,
        isFetching: isFetchingCategoriesPointsOfInterest,
        error: errorCategoriesPointsOfInterest,
    } = useGetCategoriesPointOfIntrestQuery();

    function closeModal() {
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true);
    }

    useEffect(() => {
        const tagss = tags?.data;
        if (!isFetchingTags && !errorTags) {
            let temp = [];
            Object.keys(tagss).forEach((tag) => {
                tagsIDsRedux.length === 0
                    ? temp.push({
                          id: tagss[tag].id,
                          tag: tagss[tag].tag,
                          status: false,
                      })
                    : tagsIDsRedux.forEach((tagRedux) => {
                          if (tagss[tag].id === tagRedux) {
                              temp.push({
                                  id: tagss[tag].id,
                                  tag: tagss[tag].tag,
                                  status: true,
                              });
                          } else {
                              temp.push({
                                  id: tagss[tag].id,
                                  tag: tagss[tag].tag,
                                  status: false,
                              });
                          }
                      });
            });
            setNewTags(temp);
        }
    }, [isFetchingTags]);

    useEffect(() => {
        const categoriesExp = categoriesExperience?.data;
        if (!isFetchingCategoriesExperience && !errorCategoriesExperience) {
            let temp = [];
            Object.keys(categoriesExp).forEach((category) => {
                temp.push({
                    id: categoriesExp[category].id,
                    category: categoriesExp[category].title,
                    status: false,
                });
            });
            setNewCategoriesExperiences(temp);
        }
    }, [isFetchingCategoriesExperience]);

    useEffect(() => {
        const categoriesPoi = categoriesPointsOfInterest?.data;
        if (!isFetchingCategoriesPointsOfInterest && !errorCategoriesPointsOfInterest) {
            let temp = [];
            Object.keys(categoriesPoi).forEach((category) => {
                temp.push({
                    id: categoriesPoi[category].id,
                    category: categoriesPoi[category].title,
                    status: false,
                });
            });
            setNewCategoriesPointsOfInterest(temp);
        }
    }, [isFetchingCategoriesPointsOfInterest]);

    function toggleStatusTags(tag) {
        let temp = [...newTags];
        temp.forEach((tagg) => {
            if (tagg.tag === tag) {
                tagg.status = !tagg.status;
            }
        });

        setNewTags(temp);
    }

    function toggleStatusCategories(category, arrayCategories, arrayType) {
        let temp = [...arrayCategories];
        temp.forEach((cat) => {
            if (cat.category === category) {
                cat.status = !cat.status;
            }
        });
        if (arrayType === "experience") setNewCategoriesExperiences(temp);
        else if (arrayType === "pointOfInterest") setNewCategoriesPointsOfInterest(temp);
    }

    const cleanFilter = () => {
        const tagss = tags?.data;
        if (!isFetchingTags && !errorTags) {
            let temp = [];
            Object.keys(tagss).forEach((tag) => {
                temp.push({
                    id: tagss[tag].id,
                    tag: tagss[tag].tag,
                    status: false,
                });
            });
            setNewTags(temp);
        }

        const categoriesExp = categoriesExperience?.data;
        if (!isFetchingCategoriesExperience && !errorCategoriesExperience) {
            let temp = [];
            Object.keys(categoriesExp).forEach((category) => {
                temp.push({
                    id: categoriesExp[category].id,
                    category: categoriesExp[category].title,
                    status: false,
                });
            });
            setNewCategoriesExperiences(temp);
        }

        const categoriesPoi = categoriesPointsOfInterest?.data;
        if (!isFetchingCategoriesPointsOfInterest && !errorCategoriesPointsOfInterest) {
            let temp = [];
            Object.keys(categoriesPoi).forEach((category) => {
                temp.push({
                    id: categoriesPoi[category].id,
                    category: categoriesPoi[category].title,
                    status: false,
                });
            });
            setNewCategoriesPointsOfInterest(temp);
        }

        dispatch(boundsActions.removeFilterSearch());
        dispatch(boundsActions.filterPlaces());
        dispatch(boundsActions.updateExperiences());
    };

    const filterExpPoi = () => {};

    return (
        <>
            <div className="show-hidden-sb shsb_btn shsb_btn_act" onClick={openModal}>
                <i className="fal fa-sliders-h"></i>
                <span className="">Filtre</span>
            </div>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-40" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center min-h-full p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95">
                                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                        Filtrare căutare
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <div>
                                            <h2 className="m-0 font-medium">Taguri</h2>
                                            <div className="flex-wrap space-y-2">
                                                {newTags.map((tag, index) => {
                                                    return (
                                                        <Button
                                                            key={index}
                                                            onClick={(_) => {
                                                                toggleStatusTags(tag.tag);
                                                            }}
                                                            className={classNames("!mr-1 capitalize p-button-secondary p-button-sm", {
                                                                "p-button-outlined": !tag.status,
                                                                "p-button-raised": tag.status,
                                                            })}>
                                                            {tag.tag}
                                                        </Button>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <h2 className="m-0 font-medium">Categorie experiente</h2>
                                            <div className="flex-wrap space-y-2">
                                                {newCategoriesExperiences.map((category, index) => {
                                                    return (
                                                        <Button
                                                            key={index}
                                                            onClick={(_) => {
                                                                toggleStatusCategories(
                                                                    category.category,
                                                                    newCategoriesExperiences,
                                                                    "experience"
                                                                );
                                                            }}
                                                            className={classNames("!mr-1 capitalize p-button-secondary p-button-sm", {
                                                                "p-button-outlined": !category.status,
                                                                "p-button-raised": category.status,
                                                            })}>
                                                            {category.category}
                                                        </Button>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <h2 className="m-0 font-medium">Categorie puncte de interes</h2>
                                            <div className="flex-wrap space-y-2">
                                                {newCategoriesPointsOfInterest.map((category, index) => {
                                                    return (
                                                        <Button
                                                            key={index}
                                                            onClick={(_) => {
                                                                toggleStatusCategories(
                                                                    category.category,
                                                                    newCategoriesPointsOfInterest,
                                                                    "pointOfInterest"
                                                                );
                                                            }}
                                                            className={classNames("!mr-1 capitalize p-button-secondary p-button-sm", {
                                                                "p-button-outlined": !category.status,
                                                                "p-button-raised": category.status,
                                                            })}>
                                                            {category.category}
                                                        </Button>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-4 space-x-2">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={() => {
                                                closeModal();
                                                cleanFilter();
                                            }}>
                                            Curățare filtre
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={() => {
                                                closeModal();
                                                dispatch(boundsActions.getCategoriesPOI(newCategoriesPointsOfInterest));
                                                dispatch(boundsActions.getCategoriesExp(newCategoriesExperiences));
                                                dispatch(boundsActions.getTags(newTags));
                                                dispatch(boundsActions.filterPlaces());
                                                dispatch(boundsActions.updateExperiences());
                                            }}>
                                            Filtrează
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={closeModal}>
                                            Închide
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}

export default SearchFilters;
