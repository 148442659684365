import React from "react";
import Header from "./Header";
import { Link } from "react-router-dom";

function Error() {
    return (
        <div className="flex flex-col h-full">
            <Header />
            <div className="flex flex-row items-center justify-center h-full">
                <div className="flex flex-col items-start">
                    <h1 className="text-3xl">Something's wrong here...</h1>
                    <span className="text-lg">
                        We cant find the page you're looking for. Check out our help center or head back to home
                    </span>
                    <Link to="/">
                        <button className="px-4 py-2 mt-6 text-base font-semibold text-white bg-gray-500 rounded-sm">Home</button>
                    </Link>
                </div>
                <img className="h-[30vh] max-h-[300px]" src="/questionmark.jpg" alt="" />
            </div>
        </div>
    );
}

export default Error;
