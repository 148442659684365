import { Carousel } from "primereact/carousel";
import { getApiPointsOfInterestsOfExperience } from "../../services/apiServices";
import { Link as LinkT } from "react-router-dom";
import { useEffect, useState } from "react";
import { Skeleton } from "primereact/skeleton";

const CarouselPOI = ({ experienceId }) => {
    const [poi, setPoi] = useState(null);
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const response = await getApiPointsOfInterestsOfExperience(experienceId);
            setIsFetching(response.success === true && response.error === false ? false : true);
            setPoi(response.data.data);
        };

        fetchData();
    }, []);

    const poiTemplate = (item) => {
        return (
            <div className="w-full px-1" id="carousel" key={item.id}>
                <LinkT target="_blank" to={`/pointofinterest/${item.id}`}>
                    {/* {experience?.images?.[0]?.url !== undefined ? (
                            <img
                                className="object-cover w-full h-48 rounded-lg"
                                src={`${process.env.REACT_APP_STORAGE}/${experience?.images[0]?.url}`}
                                alt={experience.title}
                            />
                        ) : (
                            <Skeleton className="w-full" height="192px"></Skeleton>
                        )} */}
                    <img
                        className="object-cover w-full h-48 rounded-lg"
                        src={
                            item?.images?.[0]?.url
                                ? `${process.env.REACT_APP_STORAGE}/${item?.images[0]?.url}`
                                : "/default/default-image.png"
                        }
                        alt={item.title ? item.title : "No title"}
                    />
                </LinkT>
                <div className="w-full h-full duration-300 ease-in-out hover:cursor-pointer">
                    <div className="py-3">
                        <h1 className="m-0 text-base font-semibold">{item.title}</h1>
                        {/* <h3 className="m-0 text-base font-light text-neutral-500">#{experience.tag}</h3> */}
                    </div>
                </div>
            </div>
        );
    };

    // dimensiunile pentru redimensionare carusel
    const responsiveOptions = [
        {
            breakpoint: "1024px",
            numVisible: 2,
            numScroll: 2,
        },
        {
            breakpoint: "768px",
            numVisible: 2,
            numScroll: 2,
        },
        {
            breakpoint: "560px",
            numVisible: 1,
            numScroll: 1,
        },
    ];
    return (
        <div className="py-3">
            {isFetching ? (
                <Skeleton width="w-full" height="10rem"></Skeleton>
            ) : poi.length === 0 ? (
                <h3>Nici un punct de interes asociat acestei experiente</h3>
            ) : (
                <div>
                    {
                        <Carousel
                            value={poi}
                            itemTemplate={poiTemplate}
                            numVisible={2}
                            numScroll={1}
                            autoplayInterval={poi.length === 1 ? null : 10000}
                            circular={true}
                            responsiveOptions={responsiveOptions}></Carousel>
                    }
                </div>
            )}
        </div>
    );
};

export default CarouselPOI;
