import React from "react";

function PreviewImage3({ images }) {
    const array_images = images;
    return (
        <div className="flex flex-row gap-2 h-[60vh] min-h-[20rem] max-h-[42rem]">
            <img
                className="object-cover sm:w-[70%] w-[100%] h-full rounded-lg"
                src={`${process.env.REACT_APP_STORAGE}/${array_images[0].url}`}
                alt={array_images[0].title}
            />
            <div className="grid grid-cols-1 grid-rows-2 gap-2">
                <img
                    className="object-cover w-full h-full rounded-lg"
                    src={`${process.env.REACT_APP_STORAGE}/${array_images[1].url}`}
                    alt={array_images[1].title}
                />
                <img
                    className="object-cover w-full h-full rounded-lg"
                    src={`${process.env.REACT_APP_STORAGE}/${array_images[2].url}`}
                    alt={array_images[2].title}
                />
            </div>
        </div>
    );
}

export default PreviewImage3;
