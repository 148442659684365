import Layout from "./Layout";

const Description = ({ experience }) => {
    return (
        <Layout title="Descriere">
            <p id="description">{experience.description}</p>
        </Layout>
    );
};

export default Description;
