import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { store } from "./app/store";
import { Provider } from "react-redux";

import "./index.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "antd/dist/antd.min.css";

import "./newTheme/css/style.css";
import "./newTheme/css/color.css";
import "./newTheme/css/plugins.css";
import "./newTheme/css/dashboard-style.css";
import "./newTheme/css/iconfix.css";
import "./newTheme/css/themefix.css";
import "./newTheme/css/themefixdragos.css";
import "./newTheme/css/mobilefix.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);

reportWebVitals();
