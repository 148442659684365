import Layout from "./Layout";

const HostedBy = ({ pointOfInterestUser, username }) => {
    return (
        <Layout title="Hosted by:">
            <div className="flex flex-row">
                <div className="flex flex-row items-center space-x-4">
                    <img className="object-cover w-12 h-12" src="/profile/avatar.jpg" alt="" />
                    <div className="flex flex-col leading-4">
                        <span className="font-bold">{username}</span>
                        <span>{pointOfInterestUser.length} puncte de interes găzduite</span>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default HostedBy;
