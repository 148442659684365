import Layout from "./Layout";
import { Link } from "react-router-dom";
import { getApiTags } from "../../../services/apiServices";
import { useEffect, useState } from "react";

const Tags = () => {
    const [tags, setTags] = useState(null);
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const response = await getApiTags();
            setIsFetching(response.success === true && response.error === false ? false : true);
            setTags(response.data);
        };

        fetchData();
    }, []);

    return (
        <Layout title="Tags">
            <div className="my-3">
                {!isFetching &&
                    tags.map((tag) => {
                        return (
                            <Link
                                to="/"
                                className="text-neutral-500 border border-slate-300 inline-block mx-1 bg-slate-100 px-2.5 py-1 mb-1.5 hover:text-white hover:bg-sky-500"
                                key={tag.id}>
                                {tag.tag}
                            </Link>
                        );
                    })}
            </div>
        </Layout>
    );
};

export default Tags;
