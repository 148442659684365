import React, { useEffect, useState } from "react";

import axios from 'axios';
import { Link } from 'react-router-dom';

import "./style.css";

function Logout() {
    const [showMessage, setShowMessage] = useState(false);

    localStorage.removeItem('userData');

    return (
        <div className="bg-[rgb(239,243,248)] h-full py-10 min-h-[700px]">
            <div className="bg-white w-[500px] mx-auto rounded-lg py-8">
                <h1 className="text-xl text-center">Deconectare</h1>
                <p className="text-l text-center">Deconectat cu succes</p>
                <br />
                <p className="text-l text-center">Navigheaza catre:</p>
                <br />
                <div className="text-l text-center">
                    <Link to="/login">Login</Link>
                    <Link style={{marginLeft:"30px"}} to="/">Acasa</Link>
                </div>
            </div>
        </div>
    );
}

export default Logout;
