import { createSlice } from "@reduxjs/toolkit";

const searchSlice = createSlice({
    name: "search",
    initialState: {
        searchInput: "",
        searchInputExp: [],
        searchInputPoi: [],
        experience: null,
        pointOfInterest: null,
        newCenterMapLat: null,
        newCenterMapLng: null,
    },
    reducers: {
        getSearchInput(state, action) {
            state.searchInput = action.payload;
            console.log(state.searchInput);
        },
        getSearchInputExp(state, action) {
            state.searchInputExp = action.payload;
            console.log(state.searchInputExp);
        },
        getSearchInputPoi(state, action) {
            state.searchInputPoi = action.payload;
            console.log(state.searchInputPoi);
        },
        getExperience(state, action) {
            state.experience = action.payload;
            console.log(state.experience);
        },
        getPointOfInterest(state, action) {
            state.pointOfInterest = action.payload;

            state.newCenterMapLat = state.pointOfInterest.latitude;
            state.newCenterMapLng = state.pointOfInterest.longitude;
            console.log(state.pointOfInterest);
            console.log(state.newCenterMapLat, state.newCenterMapLng);
        },
    },
});

export const searchActions = searchSlice.actions;

export default searchSlice;
