import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Checkbox } from "primereact/checkbox";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import { FileUpload } from "primereact/fileupload";

import {
    useGetTagsExperienceQuery,
    useGetUserOwnExperiencesMobileQuery,
    useGetCategoriesPointOfIntrestQuery,
    useGetAttractionQuery,
} from "../../services/smartsenseApi";
import "./style.css";
import axios from "axios";
import Header from "../Header";
import Footer from "../Footer";
import DashboardMenu from "../DashboardMenu";

import { useParams } from "react-router-dom";

import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

function EditPointofinterest() {
    const statusArray = [
        { name: "In Progress", code: "in_progress" },
        { name: "Finished", code: "finished" },
    ];
    let userData = getUserData();
    const [userExperiences, setUserExperiences] = useState([]);
    const [tags, setTags] = useState([]);
    const [categories, setCategories] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    const toast = useRef(null);
    const [POI, setPOI] = useState(null);
    const [theImages, setTheImages] = useState(null);
    const [deletedImages, setDeletedImages] = useState([]);
    const [markers, setMarkers] = useState([]);

    const { pointofinterestId } = useParams();

    const showSuccess = (detailValue) => {
        toast.current.show({ severity: "success", summary: "Success Message", detail: detailValue, life: 3000 });
    };

    const showError = (detailValue) => {
        toast.current.show({ severity: "error", summary: "Error Message", detail: detailValue, life: 3000 });
    };

    const clear = () => {
        toast.current.clear();
    };

    const { data: attraction, isFetching } = useGetAttractionQuery(pointofinterestId);
    const { data: tagsPOI, isFetching: isFetchingTags, error: errorTags } = useGetTagsExperienceQuery(); //used
    const {
        data: _userExperiences,
        isFetching: isFetchingExperiences,
        error: errorUserExperiences,
    } = useGetUserOwnExperiencesMobileQuery(userData.user.id); //used
    const {
        data: categoriesPOI,
        isFetching: isFetchingCategoryPointOfInterest,
        error: errorCategoryPointOfInterest,
    } = useGetCategoriesPointOfIntrestQuery();
    const singlePOI = attraction?.data;

    useEffect(() => {
        var thePOI = { ...singlePOI };
        if (singlePOI) {
            thePOI.starting_date = new Date(singlePOI.start_date);
            thePOI.finishing_date = new Date(singlePOI.end_date);
        }
        setPOI(thePOI);
        console.log("herePOI");
        console.log(thePOI);

        let poiexp = thePOI.experiences;
        let pois = [];

        poiexp?.forEach((experience) => {
            var obj = {};
            obj.id = experience.id;
            obj.experience_category_id = experience.experience_category_id;
            obj.user_id = experience.user_id;
            obj.title = experience.title;
            obj.description = experience.description;
            obj.location = experience.location;
            obj.score = experience.score;
            obj.has_ar = experience.has_ar;
            obj.has_vuforia = experience.has_vuforia;
            obj.ar_prefab = experience.ar_prefab;
            obj.visible = experience.visible;
            obj.active = experience.active;
            obj.start_date = experience.start_date;
            obj.end_date = experience.end_date;
            obj.target_qr = experience.target_qr;

            pois.push(obj);
        });
        thePOI.experiences = pois;

        formik.setValues(thePOI);
        setTheImages(thePOI.images);
    }, [isFetching]);

    useEffect(() => {
        if (!isFetchingTags) {
            setTags(tagsPOI?.data);
        }
    }, [isFetchingTags]);

    useEffect(() => {
        if (!isFetchingCategoryPointOfInterest) {
            setCategories(categoriesPOI?.data);
        }
    }, [isFetchingCategoryPointOfInterest]);

    useEffect(() => {
        if (!isFetchingExperiences) {
            let poiexp = _userExperiences?.data;
            let pois = [];

            poiexp?.forEach((experience) => {
                var obj = {};
                obj.id = experience.id;
                obj.experience_category_id = experience.experience_category_id;
                obj.user_id = experience.user_id;
                obj.title = experience.title;
                obj.description = experience.description;
                obj.location = experience.location;
                obj.score = experience.score;
                obj.has_ar = experience.has_ar;
                obj.has_vuforia = experience.has_vuforia;
                obj.ar_prefab = experience.ar_prefab;
                obj.visible = experience.visible;
                obj.active = experience.active;
                obj.start_date = experience.start_date;
                obj.end_date = experience.end_date;
                obj.target_qr = experience.target_qr;

                pois.push(obj);
            });

            setUserExperiences(pois);
        }
    }, [isFetchingExperiences]);

    const formik = useFormik({
        initialValues: {
            title: "",
            description: "",
            address: "",
            email: "",
            phone_number: "",
            latitude: "",
            longitude: "",
            mesh_name: "",
            visible: false,
            active: false,
            point_of_interest_category: "",
            images_upload: [],
            experiences: [],
            tags: [],
        },
        validate: (data) => {
            let errors = {};

            if (!data.title) {
                errors.title = "Title is required.";
            }

            if (!data.description) {
                errors.description = "Description is required.";
            }

            if (!data.address) {
                errors.address = "Address is required.";
            }

            if (!data.phone_number) {
                errors.phone_number = "Phone number is required.";
            }

            if (!data.latitude) {
                errors.latitude = "Please place the point of interest on the map.";
            }

            if (!data.latitude) {
                errors.latitude = "Please place the point of interest on the map.";
            }

            if (!data.point_of_interest_category) {
                errors.point_of_interest_category = "Category is required.";
            }

            // if (!data.tags) {
            //     errors.tags = "Tags are required.";
            // }

            // if (!data.mesh_name) {
            //     errors.mesh_name = "Mesh name is required.";
            // }

            if (!data.visible) {
                errors.visible = "Visibility is required.";
            }

            if (!data.active) {
                errors.active = "Status is required.";
            }

            // console.log(errors);

            return errors;
        },

        onSubmit: (data) => {
            var sendTags = [];
            var sendExperiences = [];

            data.experiences.map((exp, i) => {
                sendExperiences.push(exp.id);
            });
            data.tags.map((exp, i) => {
                sendTags.push(exp.id);
            });

            axios({
                method: "put",
                url: process.env.REACT_APP_API_URL + "/web/points-of-interest/" + pointofinterestId,
                data: {
                    title: data.title,
                    description: data.description,
                    address: data.address,
                    phone_number: data.phone_number,
                    latitude: data.latitude,
                    longitude: data.longitude,
                    mesh_name: "-",
                    visible: data.visible,
                    active: data.active,
                    delete_ids: deletedImages,
                    category_id: data.point_of_interest_category.id,
                    user_id: userData.user.id,
                    images_upload: data.images_upload,
                    experience_ids: sendExperiences,
                    tag_ids: sendTags,
                },
                headers: {
                    'Authorization': `Bearer ${userData.token}` 
                }
            })
                .then((response) => {
                    // console.log(response?.data);
                    showSuccess(response?.data?.message);
                    // formik.resetForm();
                })
                .catch((error) => {
                    console.log(error.response);
                    if (error.response) {
                        showError(error.response.status);
                    } else if (error.request) {
                        showError(error.request);
                    } else {
                        showError(error.message);
                    }
                });
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    function LocationMarker() {
        const [position, setPosition] = useState(null);
        const map = useMap();

        useEffect(() => {
            map.locate().once("locationfound", function (e) {
                if (markers.length == 0) {
                    if (singlePOI.latitude && singlePOI.longitude) {
                        var _position = {
                            lat: singlePOI.latitude,
                            lng: singlePOI.longitude,
                        };
                        setPosition(_position);
                        setMarkers([_position]);
                        map.flyTo(_position, "15");
                    } else {
                        setPosition(e.latlng);
                        map.flyTo(e.latlng, "15");
                    }
                }
            });
        }, [map]);

        return position === null ? null : null;
    }

    function AddMarkerOnClick() {
        const map = useMapEvents({
            click(e) {
                const newMarker = e.latlng;
                formik.values.latitude = newMarker.lat;
                formik.values.longitude = newMarker.lng;
                var testurl =
                    "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&langCode=RO&location=" +
                    newMarker.lng +
                    "," +
                    newMarker.lat;

                axios.get(testurl).then((response) => {
                    formik.values.address = response.data.address.LongLabel;
                    setMarkers([newMarker]);
                });
            },
        });

        return (
            <>
                {markers.map((marker, index) => (
                    <Marker
                        position={marker}
                        key={index}
                        icon={L.divIcon({
                            html: `
                            <svg style='fill:red' className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch' focusable='false' aria-hidden='true' viewBox='0 0 24 24' data-testid='FmdGoodIcon'><path d='M12 2c-4.2 0-8 3.22-8 8.2 0 3.32 2.67 7.25 8 11.8 5.33-4.55 8-8.48 8-11.8C20 5.22 16.2 2 12 2zm0 10c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z'></path></svg>`,
                            className: "",
                            iconSize: [34, 45],
                            iconAnchor: [15, 15],
                        })}></Marker>
                ))}
            </>
        );
    }

    const customBase64Uploader = async (event) => {
        let images = event.files;
        let temp = [];
        console.log(images);
        Object.keys(images).forEach((image) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(images[image]);
            fileReader.onload = () => {
                temp.push({
                    title: images[image].name,
                    image: fileReader.result,
                });
            };
        });
        formik.handleChange({
            target: { name: "images_upload", value: temp },
        });
        console.log(theImages);
    };

    const removeImage = (_id) => {
        console.log(_id);
        const _theImages = theImages;
        const _theImage = _theImages.findIndex((x) => x.id === _id);
        var __theImages = [...theImages];
        delete __theImages[_theImage];
        __theImages = __theImages.filter((x) => x);

        setTheImages(__theImages);
        setDeletedImages([...deletedImages, _id]);
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="bg-[rgb(239,243,248)] min-h-full newtheme">
                <Header />
                {(userExperiences && userExperiences.length) && <div className="container box-border py-8 mx-auto my-10 bg-white rounded-lg">
                    <DashboardMenu />
                    <div className="col-md-9 col-sm-12">
                        <form onSubmit={formik.handleSubmit} className="space-y-6">
                            <div className="dashboard-title fl-wrap">
                                <h3>Editeaza punctul de interes</h3>
                            </div>
                            <div className="profile-edit-container fl-wrap block_box">
                                <div className="custom-form">
                                    <div className="row">
                                        <div className="col-md-10">
                                            <label>Denumire <i className="gmui gmui-briefcase"></i></label>
                                            {getFormErrorMessage("title")}
                                            <InputText
                                                id="title"
                                                name="title"
                                                value={formik.values.title}
                                                onChange={formik.handleChange}
                                                className={classNames("w-full", { "p-invalid": isFormFieldValid("title") })}
                                            />
                                        </div>
                                        <div className="col-md-2 mt-4">
                                            <div className="row">
                                                <div>
                                                    <Checkbox
                                                        inputId="active"
                                                        name="active"
                                                        checked={formik.values.active}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ "p-invalid newtheme-checkbox": isFormFieldValid("active") })}
                                                    />
                                                    <label htmlFor="active" className={classNames("newtheme-checkbox-label", { "p-error": isFormFieldValid("active") })}>Activ</label>
                                                    {getFormErrorMessage("active")}
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div>
                                                    <Checkbox
                                                        inputId="visible"
                                                        name="visible"
                                                        checked={formik.values.visible}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ "p-invalid newtheme-checkbox": isFormFieldValid("visible") })}
                                                    />
                                                    <label htmlFor="visible" className={classNames("newtheme-checkbox-label", { "p-error": isFormFieldValid("visible") })}>Vizibil</label>
                                                    {getFormErrorMessage("visible")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label htmlFor="description" className={classNames({ "p-error": isFormFieldValid("description") })}>
                                                Descriere
                                            </label>
                                            {getFormErrorMessage("description")}
                                            <InputTextarea
                                                rows={5}
                                                cols={30}
                                                id="description"
                                                name="description"
                                                value={formik.values.description}
                                                onChange={formik.handleChange}
                                                className={classNames("w-full mb-3", { "p-invalid": isFormFieldValid("description") })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Categorie</label>
                                            {getFormErrorMessage("point_of_interest_category")}
                                            <div className="listsearch-input-item">
                                                <Dropdown
                                                    id="point_of_interest_category"
                                                    name="point_of_interest_category"
                                                    value={formik.values.point_of_interest_category}
                                                    onChange={(e) => {
                                                        formik.handleChange({
                                                            target: { name: "point_of_interest_category", value: e.value },
                                                        });
                                                    }}
                                                    options={categories}
                                                    optionLabel="title"
                                                    className="w-full chosen-select no-search-select nice-select"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label>Cuvinte cheie <i className="gmui gmui-key customKey"></i></label>
                                            {getFormErrorMessage("tags")}
                                            <div className="listsearch-input-item">
                                                <MultiSelect
                                                    id="tags"
                                                    name="tags"
                                                    value={formik.values.tags}
                                                    onChange={formik.handleChange}
                                                    options={tags}
                                                    optionLabel="tag"
                                                    className="w-full"
                                                /> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-title  dt-inbox fl-wrap">
                                <h3>Locatie / Contact</h3>
                            </div>
                            <div className="profile-edit-container fl-wrap block_box">
                                <div className="custom-form">
                                    <div className="map-container mb-3">
                                        <MapContainer
                                            center={[45.94316, 24.96676]}
                                            zoom={7}
                                            style={{ height: "500px" }}
                                            zoomControl={false}
                                        >
                                            <TileLayer
                                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            />
                                            <LocationMarker />
                                            <AddMarkerOnClick />
                                        </MapContainer>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Longitudine<i className="gmui gmui-long-arrow-alt-right"></i>  </label>
                                            <Tooltip target=".positionField" />
                                            <InputText
                                                disabled
                                                tooltip="Locatia punctului de interes se obtine pozitionand pin-ul pe harta!"
                                                tooltipOptions={{ mouseTrack: true, showOnDisabled: true}}
                                                id="longitude"
                                                name="longitude"
                                                value={formik.values.longitude}
                                                onChange={formik.handleChange}
                                                className={classNames("w-full positionField", { "p-invalid": isFormFieldValid("longitude") })}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label>Latitudine<i className="gmui gmui-long-arrow-alt-down"></i> </label>
                                            <Tooltip target=".positionField" />
                                            <InputText
                                                disabled
                                                tooltip="Locatia punctului de interes se obtine pozitionand pin-ul pe harta!"
                                                tooltipOptions={{ mouseTrack: true, showOnDisabled: true}}
                                                id="latitude"
                                                name="latitude"
                                                value={formik.values.latitude}
                                                onChange={formik.handleChange}
                                                className={classNames("w-full positionField", { "p-invalid": isFormFieldValid("latitude") })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label>Addresa<i className="gmui gmui-map-marker"></i></label>
                                            <Tooltip target=".positionField" />
                                            <InputText
                                                disabled
                                                tooltip="Locatia punctului de interes se obtine pozitionand pin-ul pe harta!"
                                                tooltipOptions={{ mouseTrack: true, showOnDisabled: true}}
                                                id="address"
                                                name="address"
                                                value={formik.values.address}
                                                onChange={formik.handleChange}
                                                className={classNames("w-full positionField", { "p-invalid": isFormFieldValid("address") })}
                                            />                                           
                                        </div>
                                        <div className="col-sm-6">
                                            <label>Telefon<i className="far fa-phone"></i>  </label>
                                            {getFormErrorMessage("phone_number")}
                                            <InputText
                                                id="phone_number"
                                                name="phone_number"
                                                value={formik.values.phone_number}
                                                onChange={formik.handleChange}
                                                className={classNames("w-full", { "p-invalid": isFormFieldValid("phone_number") })}
                                            />                                              
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label>Imagini</label>
                                        <div className="add-list-media-wrap">
                                            <div className="listsearch-input-item fl-wrap">
                                                <div className="fuzone">
                                                    <FileUpload 
                                                        customUpload onSelect={customBase64Uploader}
                                                        name="images_upload" multiple accept="image/*" 
                                                        emptyTemplate={
                                                            <div className="fu-text">
                                                                <span><i className="fa fal-images gmui gmui-images"></i> Drag-and-drop imagini aici</span>
                                                            </div>
                                                        } 
                                                    />
                                                    {(theImages?.length > 0) && 
                                                        <div style={{ padding: "0 10px" }}>
                                                            {
                                                                theImages.map(function (object, i) {
                                                                    return <div className="mb-6 mr-1 float-left" key={i} style={{ maxWidth: 250 }}>
                                                                        <img className="mt-6 mb-1" style={{ display: "inline-block" }} src={'http://dev.smartsense.ro/storage/' + object.url}></img>
                                                                        <span onClick={() => removeImage(object.id)} className="text-base color2-bg p-button p-component p-button-raised p-button-secondary p-button-text p-button-sm">Elimina</span>
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-title  dt-inbox fl-wrap">
                                    <h3>Experiente</h3>
                            </div>
                            <div className="profile-edit-container fl-wrap block_box">
                                <div className="custom-form">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                            <label>Experiente care au asociat acest punct de interes <i className="gmui gmui-location customKey"></i></label>
                                            <div className="listsearch-input-item">
                                                <MultiSelect
                                                    id="experiences"
                                                    name="experiences"
                                                    onChange={formik.handleChange}
                                                    options={userExperiences}
                                                    // options={testExperiences}
                                                    value={formik.values.experiences}
                                                    // value={testSelectedExperiences}
                                                    optionLabel="title"
                                                    className="w-full"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Button type="submit" label="Salvează" className="mt-4 mb-4 mx-auto newtheme-submit" />
                        </form>
                    </div>
                </div>
                }  
                <Footer />

            </div>
        </>
    );
}

export default EditPointofinterest;
