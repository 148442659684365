import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import MiniMap from "../map/MiniMapPOI";

import { Carousel } from "primereact/carousel";

import ShareIcon from "@mui/icons-material/Share";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useGetAttractionQuery, useGetExperienceOfPointOfIntrestQuery } from "../../services/smartsenseApi";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Markup } from "interweave";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import utils from "../../utils/utils";

function Pointofinterest() {
    let navigate = useNavigate();
    const { pointofinterestId } = useParams();
    const [distancePOIUserLocation, setDistancePOIUserLocation] = useState(0);
    const [points_of_intrest, setPoints_of_intrest] = useState([]);
    const [experientesPOI, setExperientesPOI] = useState([]);
    const [showExperiencesPOI, setShowExperiencesPOI] = useState(false);
    const { data: attraction, isFetching: isFetchingAttraction, error: errorAttraction } = useGetAttractionQuery(pointofinterestId);
    const {
        data: experientes,
        isFetching: isFetchingExperientes,
        error: errorExperientes,
    } = useGetExperienceOfPointOfIntrestQuery(pointofinterestId);
    const userLocation = useSelector((state) => state.place.userLocation);

    useEffect(() => {
        if (!isFetchingAttraction) {
            if (errorAttraction?.data?.success === false) navigate("/error/404");
            else if (attraction?.success) {
                setPoints_of_intrest(attraction?.data);
                setDistancePOIUserLocation(
                    utils.getDistanceFromLatLonInKm(
                        userLocation.lat,
                        userLocation.lng,
                        attraction?.data?.latitude,
                        attraction?.data?.longitude
                    )
                );
            }
        }
    }, [isFetchingAttraction]);

    useEffect(() => {
        if (!isFetchingExperientes) {
            setExperientesPOI(experientes?.data);
            setShowExperiencesPOI(true);
        }
    }, [isFetchingExperientes]);

    const responsiveOptions = [
        {
            breakpoint: "1024px",
            numVisible: 2,
            numScroll: 2,
        },
        {
            breakpoint: "768px",
            numVisible: 2,
            numScroll: 2,
        },
        {
            breakpoint: "560px",
            numVisible: 1,
            numScroll: 1,
        },
    ];

    const experienceTemplate = (poi) => {
        return (
            <div className="w-full px-1" key={poi.id}>
                <Link target="_blank" to={`/experience/${poi.id}`}>
                    <img
                        className="object-cover w-full h-48 rounded-lg"
                        src={
                            poi?.images?.[0]?.url !== undefined
                                ? `http://dev.smartsense.ro/storage/${poi?.images[0]?.url}`
                                : "https://semantic-ui.com/images/wireframe/image.png"
                        }
                        alt={poi.title}
                    />
                </Link>
                <div className="w-full h-full duration-300 ease-in-out hover:cursor-pointer">
                    <div className="py-3">
                        <h1 className="m-0 text-base font-semibold">{poi.title}</h1>
                        {/* <h3 className="m-0 text-base font-light text-neutral-500">#{experience.tag}</h3> */}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <Header />
            <div className="container mx-auto">
                <h1 className="my-3 text-3xl font-base">{points_of_intrest?.title}</h1>
                <div className="flex flex-col items-center justify-between md:flex-row">
                    <div className="flex flex-row items-center space-x-3">
                        {/* {points_of_intrest.rating && <h3>★ {points_of_intrest?.rating}</h3>} */}

                        {points_of_intrest.address && (
                            <h3>
                                · <span className="font-semibold">{points_of_intrest.address}</span>
                            </h3>
                        )}

                        {points_of_intrest.phone_number && (
                            <h3>
                                · <span className="font-semibold">(+40){points_of_intrest.phone_number}</span>
                            </h3>
                        )}
                        {userLocation.length !== 0 && (
                            <h3>
                                · <span className="font-semibold underline underline-offset-1">{parseInt(distancePOIUserLocation)} Km</span>
                            </h3>
                        )}
                    </div>
                    <div className="flex flex-row space-x-1">
                        <button className="flex items-center hover:bg-[rgb(240,240,240)] py-1 px-2 rounded-lg">
                            <ShareIcon fontSize="small" />
                            <span>Trimite</span>
                        </button>
                        <button className="flex items-center hover:bg-[rgb(240,240,240)] py-1 px-2 rounded-lg">
                            <FavoriteBorderIcon fontSize="small" />
                            <span>Favorit</span>
                        </button>
                    </div>
                </div>

                <div className="flex flex-row gap-2 my-3 h-[60vh] min-h-[20rem] max-h-[42rem]">
                    <img
                        className="object-cover w-full h-full rounded-lg"
                        src={
                            points_of_intrest?.images?.[0]?.url !== undefined
                                ? `http://dev.smartsense.ro/storage/${points_of_intrest?.images[0]?.url}`
                                : "https://semantic-ui.com/images/wireframe/image.png"
                        }
                        alt=""
                    />
                </div>

                <div className="my-4 md:w-[65%] w-full">
                    {/* Experiente si taguri */}
                    <div className="py-3 border-b">
                        <div className="flex flex-row mb-2 space-x-2 text-lg font-medium">
                            {points_of_intrest.tags &&
                                points_of_intrest.tags.map((tag, index) => {
                                    return <span key={index}>#{tag.tag.toLowerCase()}</span>;
                                })}
                        </div>
                        {points_of_intrest.tags && (
                            <h3 className="mb">
                                {points_of_intrest.tags.length} {points_of_intrest.tags.length === 1 && "singur tip de experientă."}
                                {points_of_intrest.tags.length > 1 && "tipuri diferite de experientă"}
                            </h3>
                        )}
                    </div>

                    {/* Descriere */}
                    <div className="py-3 border-b">
                        <div className="whitespace-pre-line">
                            {points_of_intrest.description && (
                                <Markup
                                    content={points_of_intrest.description
                                        .split("\r\n")
                                        .map((s) => `<p class="indent-4">${s}</p>`)
                                        .join("\r\n")}
                                />
                            )}
                        </div>
                    </div>

                    {/* Experiente dezvoltat */}

                    {showExperiencesPOI && (
                        <div className="py-3 border-b">
                            <h1 className="mb-4 text-xl font-medium">Experiențe</h1>
                            <div>
                                {
                                    <Carousel
                                        value={experientesPOI}
                                        itemTemplate={experienceTemplate}
                                        numVisible={2}
                                        numScroll={1}
                                        autoplayInterval={10000}
                                        circular={true}
                                        responsiveOptions={responsiveOptions}></Carousel>
                                }
                            </div>
                        </div>
                    )}
                </div>

                <div className="pb-4">
                    <h1 className="mb-4 text-2xl">Locație</h1>
                    <MiniMap iconType={"home"} point_of_intrest_id={pointofinterestId} />
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Pointofinterest;
