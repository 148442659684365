import React from "react";
import { Skeleton } from "primereact/skeleton";

function PlacesSkeleton() {
    return (
        <div className="">
            <div>
                <Skeleton width="w-full" height="15rem"></Skeleton>
                <Skeleton className="my-2" height="1.5rem" width="15rem"></Skeleton>
                <div className="grid grid-cols-2 gap-x-2">
                    <Skeleton width="w-full" height="1.5rem"></Skeleton>
                    <Skeleton width="w-full" height="1.5rem"></Skeleton>
                </div>
            </div>
        </div>
    );
}

export default PlacesSkeleton;
