const Layout = ({ children, title }) => {
    return (
        <div className="flex flex-col bg-white border rounded-xl border-slate-400">
            <h1 className="px-4 my-2 text-md">{title}</h1>
            <div className="w-full h-px bg-slate-300" />
            <div className="px-4 my-2 text-neutral-500">{children}</div>
        </div>
    );
};
export default Layout;
