import { createSlice } from "@reduxjs/toolkit";

const listSlice = createSlice({
    name: "list",
    initialState: {
        showList: false,
        pinSelected: false,
        selectedPlaceholder: null,
        togglePOIExp: true,
        toggleMapMod: true,
    },
    reducers: {
        toggleList(state) {
            state.showList = !state.showList;
        },
        statusPlaceList(state, action) {
            const place = action.payload;

            if (state.selectedPlaceholder !== place) state.selectedPlaceholder = place;

            if (place === null) {
                state.pinSelected = false;
                state.showList = false;
            } else {
                state.pinSelected = true;
                state.showList = true;
            }
        },
        togglePoiExp(state) {
            state.togglePOIExp = !state.togglePOIExp;
        },
        toggleMapMode(state) {
            state.toggleMapMod = !state.toggleMapMod;
        },
    },
});

export const listActions = listSlice.actions;

export default listSlice;
