import { createSlice } from "@reduxjs/toolkit";

const boundsSlice = createSlice({
    name: "bounds",
    initialState: {
        all_places: [],
        places: [],
        places_copy: [],
        categoriesIDs: [],
        tagsIDs: [],
        categoriesExpIDs: [],
        tagsExpIDs: [],
        filterStatus: false,
        all_experiences: [],
        experiences: [],
        countExperiences: true,
        countPOI: true,
    },
    reducers: {
        filterPlaces(state) {
            state.places = state.places_copy;
            let status = true;
            let temp1 = [];
            let temp2 = [];
            let temp = [];

            // console.log({ ...state.places });

            if (state.categoriesIDs.length === 0 && state.tagsIDs.length === 0) {
                // Nu exista filtre taguri si nici categori active
                status = false;
            } else if (state.categoriesIDs.length === 0 && state.tagsIDs.length !== 0) {
                // Exista doar filtre taguri
                state.places.forEach((place) => {
                    place.tags.forEach((tag) => {
                        state.tagsIDs.forEach((tag_id) => {
                            if (tag.id === tag_id) temp1.push(place);
                        });
                    });
                });
                temp = new Set([...temp1]);
                temp = Array.from(temp);

                // console.log("poi dupa filtrarea in fucntie de taguri", { ...temp });
            } else if (state.categoriesIDs.length !== 0 && state.tagsIDs.length === 0) {
                // Exista doar filte categori
                state.places.forEach((place) => {
                    state.categoriesIDs.forEach((category_id) => {
                        if (place.point_of_interest_category.id === category_id) temp2.push(place);
                    });
                });

                temp = new Set([...temp2]);
                temp = Array.from(temp);
            } else if (state.categoriesIDs.length !== 0 && state.tagsIDs.length !== 0) {
                state.places.forEach((place) => {
                    // Exista filte categori si taguri
                    state.categoriesIDs.forEach((category_id) => {
                        if (place.point_of_interest_category.id === category_id) temp1.push(place);
                    });
                });

                temp1.forEach((place) => {
                    place.tags.forEach((tag) => {
                        state.tagsIDs.forEach((tag_id) => {
                            if (tag.id === tag_id) temp2.push(place);
                        });
                    });
                });

                temp = new Set([...temp2]);
                temp = Array.from(temp);
            }

            if (status) {
                // console.log("aici trebuie sa se intample", [...temp]);
                state.places = [...temp];
                // state.places_copy = [...temp];
                state.filterStatus = true;

                const countPoi = state.places.length;
                if (countPoi === 0) {
                    state.countPOI = false;
                } else state.countPOI = true;
            } else {
                state.places = state.places_copy;
                state.filterStatus = false;

                const countPoi = state.places_copy.length;
                if (countPoi === 0) {
                    state.countPOI = false;
                } else state.countPOI = true;
            }
        },
        getCategoriesPOI(state, action) {
            const categories = action.payload;
            state.categoriesIDs = [];
            let temp = [];

            categories.forEach((category) => {
                if (category.status === true) temp.push(category.id);
            });

            state.categoriesIDs = temp;
        },
        getCategoriesExp(state, action) {
            const categories = action.payload;
            state.categoriesExpIDs = [];
            let temp = [];

            categories.forEach((category) => {
                if (category.status === true) temp.push(category.id);
            });

            state.categoriesExpIDs = temp;
        },
        getTagsId(state, action) {
            const tagsId = action.payload;
            state.tagsIDs = tagsId;
            state.tagsExpIDs = tagsId;
        },
        getTags(state, action) {
            const tags = action.payload;
            state.tagsIDs = [];
            state.tagsExpIDs = [];
            let temp = [];

            tags.forEach((category) => {
                if (category.status === true) temp.push(category.id);
            });

            // console.log(temp);

            state.tagsIDs = temp;
            state.tagsExpIDs = temp;
        },
        updateAllLitePlaces(state, action) {
            state.all_places = action.payload;
        },

        updateAllLiteExperiences(state, action) {
            state.all_experiences = [];
            state.all_experiences = action.payload;
        },
        removeFilterSearch(state, action) {
            state.tagsIDs = [];
            state.tagsExpIDs = [];
            state.categoriesIDs = [];
            state.categoriesExpIDs = [];
        },
        updateExperiences(state) {
            let experience_ids = [];
            // state.places = state.places_copy;

            state.places.forEach((poi) => {
                experience_ids.push(...poi.experiences);
            });

            const uniqueSet = new Set(experience_ids);
            const uniqueExperienceIds = Array.from(uniqueSet);

            let temp = [];
            let temp1 = [];
            let temp2 = [];

            if (state.all_experiences.length !== 0) {
                // console.log({ ...state.getCategoriesExp, ...state.getTags });
                state.all_experiences.forEach((exp) => {
                    // console.log(Object.keys(exp));
                    // console.log({ ...exp.tags });
                    uniqueExperienceIds.forEach((exp_id, index) => {
                        if (exp.id === exp_id) {
                            temp.push(exp);
                        }
                    });
                });

                if (state.tagsExpIDs.length !== 0) {
                    temp.forEach((exp) => {
                        // Utilizăm o variabilă suplimentară pentru a urmări dacă exp a fost deja găsit
                        let found = false;
                        state.tagsExpIDs.forEach((tagId) => {
                            // Utilizăm metoda 'some' pentru a verifica dacă 'exp' îndeplinește condiția
                            if (exp.tags.some((expTag) => tagId === expTag.id)) {
                                // Dacă exp îndeplinește condiția și nu a fost deja găsit, îl adăugăm în temp1
                                if (!found) {
                                    temp1.push(exp);
                                    found = true; // Setăm found la true pentru a evita adăugarea repetată a lui exp în temp1
                                }
                            }
                        });
                    });
                } else {
                    temp1 = [...temp];
                }

                // mobile are experience_category_id iar web are experience_category
                if (state.categoriesExpIDs.length !== 0) {
                    temp1.forEach((category) => {
                        let found = false;
                        state.categoriesExpIDs.forEach((categoryId) => {
                            if (category.experience_category_id === categoryId) {
                                if (!found) {
                                    temp2.push(category);
                                    found = true;
                                }
                            }
                        });
                    });
                } else {
                    temp2 = [...temp1];
                }
            }

            state.experiences = [...temp2];

            const countExperiences = state.experiences.length;
            if (countExperiences === 0) {
                state.countExperiences = false;
            } else state.countExperiences = true;
        },
        updatePlaces(state, action) {
            const newBounds = action.payload;
            state.places = [];
            state.places_copy = [];

            let places_temp = [];
            let places_copy_temp = [];
            if (state.all_places !== [])
                state.all_places.forEach((place) => {
                    if (
                        newBounds._southWest.lat < place.latitude &&
                        newBounds._northEast.lat > place.latitude &&
                        newBounds._southWest.lng < place.longitude &&
                        newBounds._northEast.lng > place.longitude
                    ) {
                        places_temp.push(place);
                        places_copy_temp.push(place);
                    }
                });

            state.places = places_temp;
            state.places_copy = places_copy_temp;

            const countPoi = state.places.length;
            if (countPoi === 0) {
                state.countPOI = false;
            } else state.countPOI = true;

            if (state.filterStatus === true) {
                state.places = state.places_copy;
                let status = true;
                let temp1 = [];
                let temp2 = [];
                let temp = [];

                if (state.categoriesIDs.length === 0 && state.tagsIDs.length === 0) {
                    // Nu exista filtre taguri si nici categori active
                    status = false;
                } else if (state.categoriesIDs.length === 0 && state.tagsIDs.length !== 0) {
                    // Exista doar filtre taguri
                    state.places.forEach((place) => {
                        place.tags.forEach((tag) => {
                            state.tagsIDs.forEach((tag_id) => {
                                if (tag.id === tag_id) temp1.push(place);
                            });
                        });
                    });
                    temp = new Set([...temp1]);
                    temp = Array.from(temp);
                } else if (state.categoriesIDs.length !== 0 && state.tagsIDs.length === 0) {
                    // Exista doar filte categori
                    state.places.forEach((place) => {
                        state.categoriesIDs.forEach((category_id) => {
                            if (place.point_of_interest_category.id === category_id) temp2.push(place);
                        });
                    });

                    temp = new Set([...temp2]);
                    temp = Array.from(temp);
                } else if (state.categoriesIDs.length !== 0 && state.tagsIDs.length !== 0) {
                    state.places.forEach((place) => {
                        // Exista filte categori si taguri
                        state.categoriesIDs.forEach((category_id) => {
                            if (place.point_of_interest_category.id === category_id) temp1.push(place);
                        });
                    });

                    temp1.forEach((place) => {
                        place.tags.forEach((tag) => {
                            state.tagsIDs.forEach((tag_id) => {
                                if (tag.id === tag_id) temp2.push(place);
                            });
                        });
                    });

                    temp = new Set([...temp2]);
                    temp = Array.from(temp);
                }

                if (status) {
                    // console.log("aici trebuie sa se intample update", [...temp]);
                    state.places = [...temp];
                    state.filterStatus = true;

                    const countPoi = state.places.length;
                    if (countPoi === 0) {
                        state.countPOI = false;
                    } else state.countPOI = true;
                } else {
                    state.places = state.places_copy;
                    state.filterStatus = false;

                    const countPoi = state.places_copy.length;
                    if (countPoi === 0) {
                        state.countPOI = false;
                    } else state.countPOI = true;
                }
            }
        },
    },
});

export const boundsActions = boundsSlice.actions;

export default boundsSlice;
