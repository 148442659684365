import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import { classNames } from "primereact/utils";
import "./style.css";
import { Link } from "react-router-dom";

import axios from "axios";
import { useNavigate } from "react-router-dom";

function Register() {
    const navigate = useNavigate();

    const [checked, setChecked] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            password: "",
            confirm_password: "",
            accept: false,
        },

        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = "Numele este necesar.";
            }

            if (!data.email) {
                errors.email = "Emailul este necesar.";
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                errors.email = "Invalid email address. E.g. example@email.com";
            }

            if (!data.password) {
                errors.password = "Parola este necesara.";
            }

            if (!data.confirm_password) {
                errors.confirm_password = "Confirmarea parolei este necesara.";
            } else if (data.password !== data.confirm_password) {
                errors.confirm_password = "Parolele trebuie sa fie fie identice";
            }

            if (!data.accept) {
                errors.accept = "Trebuie sa fii de acord cu termenii si conditiile de utilizare.";
            }

            return errors;
        },
        onSubmit: (data) => {
            axios({
                method: "post",
                url: process.env.REACT_APP_API_URL + "/register",
                data: {
                    email: data.email,
                    password: data.password,
                    confirm_password: data.confirm_password,
                    name: data.name,
                },
            }).then(
                (response) => {
                    // sessionStorage.setItem('userData',response.data.data);

                    navigate("/login");
                },
                (error) => {
                    alert("Inregistrare esuata");

                    console.log(error);
                }
            );

            formik.resetForm();
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const passwordHeader = <h6>Alege o parola</h6>;
    const passwordFooter = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Sugestii</p>
            <ul className="pl-2 mt-0 ml-2" style={{ lineHeight: "1.5" }}>
                <li>Cel putin o litera mare</li>
                <li>Cel putin o litera mica</li>
                <li>Cel putin o cifra</li>
                <li>Cel putin 8 caractere</li>
            </ul>
        </React.Fragment>
    );

    return (
        <div className="bg-[#f6f6f6] h-full flex items-center justify-center">
            <div className="bg-white w-[500px] mx-auto rounded-lg">
                <div className="pl-4 bg-[#4E65A3] rounded-t-lg text-white py-3">
                    <div className="font-bold">
                        Bine ați venit pe <span className="text-[rgb(77,183,254)]">SMART</span>SENSE
                        <span className="text-[rgb(77,183,254)]">.</span>
                    </div>
                </div>

                <div className="mx-4">
                    <div className="my-3 space-y-3">
                        <div className="grid grid-cols-2">
                            <Link to="/login" className="space-y-3 text-black hover:cursor-pointer">
                                <div className="flex flex-row mt-4 space-x-2">
                                    <i className="text-sky-400 gmui gmui-login"></i>
                                    <span>Autentificare</span>
                                </div>

                                <div className="h-px bg-neutral-200"></div>
                            </Link>
                            <Link to="/register" className="space-y-3 text-black hover:cursor-pointer">
                                <div className="flex flex-row mt-4 space-x-2">
                                    <i className="text-sky-400 gmui gmui-add-user"></i>
                                    <span>Inregistrare</span>
                                </div>
                                <div className="h-0.5 bg-sky-400"></div>
                            </Link>
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit} className="py-4 space-y-6">
                        {/* Name */}
                        <div className="space-y-2 row">
                            <label htmlFor="name" className={classNames("ml-1 font-semibold", { "p-error": isFormFieldValid("name") })}>
                                Nume *
                            </label>
                            <div className="col-md-12">
                                <InputText
                                    id="name"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    autoFocus
                                    className={classNames("w-full", { "p-invalid": isFormFieldValid("name") })}
                                />
                            </div>
                            {getFormErrorMessage("name")}
                        </div>

                        {/* Email */}
                        <div className="space-y-2 row">
                            <label htmlFor="email" className={classNames("ml-1 font-semibold", { "p-error": isFormFieldValid("email") })}>
                                Adresă Email *
                            </label>
                            <div className="col-md-12">
                                <InputText
                                    id="email"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    autoFocus
                                    className={classNames("w-full", { "p-invalid": isFormFieldValid("email") })}
                                />
                            </div>
                            {getFormErrorMessage("email")}
                        </div>

                        {/* Password */}
                        <div className="space-y-2 row">
                            <label
                                htmlFor="password"
                                className={classNames("ml-1 font-semibold", { "p-error": isFormFieldValid("password") })}>
                                Parolă *
                            </label>
                            <div className="col-md-12">
                                <Password
                                    id="password"
                                    name="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    className={classNames("w-full", { "p-invalid": isFormFieldValid("password") })}
                                    feedback={false}
                                />
                            </div>

                            {getFormErrorMessage("password")}
                        </div>

                        {/* Confirm password */}
                        <div className="space-y-2 row">
                            <label
                                htmlFor="confirm_password"
                                className={classNames("ml-1 font-semibold", { "p-error": isFormFieldValid("confirm_password") })}>
                                Confirmare Parolă *
                            </label>
                            <div className="col-md-12">
                                <Password
                                    id="confirm_password"
                                    name="confirm_password"
                                    value={formik.values.confirm_password}
                                    onChange={formik.handleChange}
                                    className={classNames("w-full", { "p-invalid": isFormFieldValid("confirm_password") })}
                                    feedback={false}
                                />
                            </div>

                            {getFormErrorMessage("confirm_password")}
                        </div>

                        {/* Agreement */}
                        <div className="field-checkbox">
                            <Checkbox
                                inputId="accept"
                                name="accept"
                                checked={formik.values.accept}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": isFormFieldValid("accept") })}
                            />
                            <label htmlFor="accept" className={classNames("ml-2", { "p-error": isFormFieldValid("accept") })}>
                                Sunt de acord cu <a href="/terms">termenii si conditiile</a> serviciului*
                            </label>
                        </div>

                        <div>
                            <button
                                className="w-44 h-10 bg-[#384F95] rounded-md text-white px-4 flex items-center transition-transform duration-300 relative"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}>
                                <span style={{ whiteSpace: "nowrap" }}>Înregistrează-te</span>
                                <i
                                    className={`text-xl text-sky-400 gmui gmui-arrow-right ${isHovered ? "ml-6" : "ml-3"}`}
                                    style={{ transition: "margin-left 300ms" }}></i>
                            </button>
                            {/* <div className="mt-3 float-btn" style={{ lineHeight: "2.75rem" }}>
                                Sau <a href="/register">completeaza formularul</a> pentru a crea un cont.
                            </div> */}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Register;
