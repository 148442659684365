import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap, ZoomControl, CircleMarker } from "react-leaflet";
import { Skeleton } from "primereact/skeleton";
import utils from "../../utils/utils";
import { getApiPointsOfInterestsOfExperience } from "../../services/apiServices";

function MiniMapExperience({ iconType, experience_id }) {
    // const redOptions = { color: "Royal Blue" };
    const [pointsOfIntrest, setPointsOfIntrest] = useState([]);
    const [sumLat, setSumLat] = useState(0);
    const [sumLng, setSumLng] = useState(0);
    const [poi, setPoi] = useState([]);
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const response = await getApiPointsOfInterestsOfExperience(experience_id);
            setIsFetching(response.success === true && response.error === false ? false : true);
            if (response.success === true && response.error === false && response.data.length !== 0) {
                setPoi(response.data.data);
                let sumLatitude = 0;
                let sumLongitude = 0;

                response.data.data.forEach((poi) => {
                    sumLatitude += poi?.latitude;
                    sumLongitude += poi?.longitude;
                });

                console.log(response.data);
                const lengthPointsOfInterest = response.data.data.length;
                const lat = sumLatitude / lengthPointsOfInterest;
                const lng = sumLongitude / lengthPointsOfInterest;

                console.log(lengthPointsOfInterest, lat, lng);

                setSumLat(lat);
                setSumLng(lng);
            }
        };

        if (experience_id !== undefined) fetchData();
    }, [experience_id]);

    return (
        <div>
            {/* {isFetching ? (
                <Skeleton className="mb-4" width="10rem" height="1.25rem"></Skeleton>
            ) : (
                <h3 className="mb-4 font-normal text-gray-600 text-md">{pointsOfIntrest.length} puncte de interes</h3>
            )} */}
            {!isFetching ? (
                poi.length === 0 ? (
                    <MapContainer
                        center={[45.2412834, 27.9318981]}
                        zoom={12}
                        scrollWheelZoom={false}
                        style={{ height: "500px", width: "100%", zIndex: "0" }}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                    </MapContainer>
                ) : (
                    <MapContainer center={[sumLat, sumLng]} zoom={12} scrollWheelZoom={false} style={{ height: "500px", width: "100%" }}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {poi?.map((pointofinterest, index) => {
                            return (
                                <Marker
                                    key={index}
                                    position={[pointofinterest?.latitude, pointofinterest?.longitude]}
                                    icon={utils.changeColor()}>
                                    <Popup>{pointofinterest?.title}</Popup>
                                </Marker>
                            );
                        })}
                    </MapContainer>
                )
            ) : (
                <Skeleton width="100%" height="500px"></Skeleton>
            )}
        </div>
    );
}

export default MiniMapExperience;
