import Footer from "../Footer";
import Header from "../Header";

const TermsAndConditionsPage = () => {
    return (
        <>
            <Header />
            <div className="container px-10 my-10">
                <h1 className="text-4xl">Smartsense - Termeni și Condiții</h1>
                <span>Ultima actualizare: 24 august 2023</span>
                <p>
                    Vă rugăm să citiți cu atenție acești Termeni și Condiții ("Termeni", "Termeni și Condiții") înainte de a utiliza
                    website-ul Smartsense (denumit în continuare "Serviciul") operat de Altfactor SRL ("noi", "ne" sau "nostru"). Accesul și
                    utilizarea Serviciului sunt condiționate de acceptarea și conformitatea dumneavoastră cu acești Termeni. Acești Termeni
                    se aplică tuturor vizitatorilor, utilizatorilor și altor persoane care accesează sau utilizează Serviciul.
                </p>
                <h2 className="text-xl">1. Acceptarea Termenilor</h2>
                <p>
                    Prin accesarea sau utilizarea Serviciului, sunteți de acord să fiți obligat de acești Termeni. Dacă nu sunteți de acord
                    cu oricare parte a termenilor, nu aveți dreptul să accesați Serviciul.
                </p>
                <h2 className="text-xl">2. Conturi de Utilizator</h2>
                <p>2.1 Trebuie să creați un cont pe website-ul Smartsense pentru a accesa anumite caracteristici și servicii.</p>
                <p>
                    2.2 Sunteți responsabil de menținerea securității contului dumneavoastră și a parolei. Noi nu putem și nu vom fi
                    răspunzători pentru nicio pierdere sau daună cauzată de nerespectarea acestei obligații de securitate.
                </p>
                <p>
                    2.3 Nu trebuie să partajați datele de autentificare ale contului dumneavoastră sau să permiteți altora să acceseze
                    contul dumneavoastră.
                </p>
                <h2 className="text-xl">3. Conținut Generat de Utilizator</h2>
                <p>
                    3.1 Utilizatorii pot încărca, crea sau posta texte, imagini și alte conținuturi ("Conținut de Utilizator") pe Serviciu.
                </p>
                <p>
                    3.2 Prin încărcarea Conținutului de Utilizator, ne acordați un drept neexclusiv, fără plată de redevențe, la nivel
                    mondial, pe toată perioada de valabilitate și irevocabil de a utiliza, reproduce, modifica, adapta, publica, traduce,
                    crea lucrări derivate, distribui și afișa astfel de Conținut de Utilizator.
                </p>
                <p>
                    3.3 Sunteți singurul responsabil pentru conținutul pe care îl postați, iar acesta nu trebuie să încalce drepturile
                    terțelor părți, să fie defăimător, ilegal sau să încalce orice legi aplicabile.
                </p>
                <h2 className="text-xl">4. Achiziții</h2>
                <p>4.1 Utilizatorii pot achiziționa bunuri, articole sau servicii prin plăți într-o singură tranșă.</p>
                <p>
                    4.2 Toate plățile sunt procesate în mod securizat. Noi nu stocăm și nu avem acces la informațiile dumneavoastră de
                    plată.
                </p>
                <h2 className="text-xl">5. Abonamente</h2>
                <p>
                    5.1 Oferim abonamente care oferă acces la caracteristici și conținut premium. Abonamentele sunt supuse unor termeni
                    specifici detaliați la momentul achiziției.
                </p>
                <p>5.2 Abonamentele sunt reînnoite automat dacă nu sunt anulate înainte de data de reînnoire.</p>
                <h2 className="text-xl">6. Proprietate Intelectuală</h2>
                <p>
                    6.1 Toate conținuturile, inclusiv, dar fără a se limita la logo-uri, design vizual, mărci comerciale și materiale
                    protejate de drepturi de autor, sunt proprietatea exclusivă a Altfactor SRL. Nu aveți dreptul să utilizați, reproduceți,
                    distribuiți sau creați lucrări derivate din proprietatea noastră intelectuală fără permisiunea scrisă explicită.
                </p>
                <h2 className="text-xl">7. Confidențialitate</h2>
                <p>
                    7.1 Utilizarea Serviciului este guvernată și de <a href="/privacy-policy">Politica noastră de Confidențialitate</a>. Prin utilizarea Serviciului, vă exprimați consimțământul cu privire la colectarea, utilizarea și
                    dezvăluirea informațiilor dumneavoastră conform descrierii din Politica de Confidențialitate.
                </p>
                <h2 className="text-xl">8. Încheierea</h2>
                <p>
                    8.1 Putem încheia sau suspenda contul dumneavoastră și accesul la Serviciu imediat, fără notificare prealabilă sau fără
                    a ne asuma răspunderea, din orice motiv, inclusiv fără limitare în cazul în care încălcați Termenii.
                </p>
                <h2 className="text-xl">9. Legea Aplicabilă</h2>
                <p>
                    9.1 Acești Termeni vor fi guvernați și interpretați în conformitate cu legile din România, fără a ține cont de
                    conflictele de legi.
                </p>
                <h2 className="text-xl">10. Modificări</h2>
                <p>
                    10.1 Ne rezervăm dreptul, la discreția noastră exclusivă, de a modifica sau înlocui acești Termeni în orice moment.
                    Termenii actualizați vor fi publicați pe această pagină. Este responsabilitatea dumneavoastră să revizuiți periodic
                    acești Termeni pentru eventuale modificări.
                </p>
                <h2 className="text-xl">Contact</h2>
                <p>Dacă aveți întrebări cu privire la acești Termeni, vă rugăm să ne contactați la altfactor@gmail.com.</p>
                <p>
                    Prin accesarea sau utilizarea website-ului Smartsense, recunoașteți că ați citit, înțeles și sunteți de acord să fiți
                    obligat de acești Termeni și Condiții.
                </p>
            </div>
            <Footer />
        </>
    );
};

export default TermsAndConditionsPage;
